import React from 'react';

import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import { IconArrowRight } from '@tabler/icons-react';

import { trpc } from '~/lib/trpc';

import { paths } from '../../paths';
import { getImagePublicUrl } from '../../utils/get-image-public-url';
import { getInitials } from '../../utils/get-initials';
import { getEmployeeRoleLabel } from '../../utils/get-labels';
import { RouterLink } from '../RouterLink';
import Loading from '../ui/Loading';
import { NotFound } from '../ui/NotFound';

type EmployeeCardProps = {
  employeeId: string;
};

export const EmployeeCard = ({ employeeId }: EmployeeCardProps) => {
  const { data: employee, isLoading } = trpc.employees.getEmployee.useQuery({
    employeeId,
  });

  if (isLoading) {
    return <Loading />;
  }

  if (!employee) {
    return <NotFound message='Employee not found!' showGoBack />;
  }

  return (
    <Card>
      <CardHeader
        title={employee.full_name}
        subheader={getEmployeeRoleLabel(employee.role)}
        avatar={
          <Avatar
            src={
              employee.avatar_url ? getImagePublicUrl(employee.avatar_url) : ''
            }
            sx={{
              height: 64,
              width: 64,
            }}
          >
            {getInitials(employee.full_name)}
          </Avatar>
        }
        action={
          <IconButton
            component={RouterLink}
            href={paths.employees.details(employee.id)}
          >
            <IconArrowRight />
          </IconButton>
        }
      />
    </Card>
  );
};
