import { DIAGNOSTIC_PROVIDER_TYPE } from '@/types/enums';

import { capitalize } from './capitalize';
import {
  APPOINTMENT_PAYMENT_STATUS,
  APPOINTMENT_STATUS,
  APPOINTMENT_TYPE,
  CLIENT_COMMUNICATION_METHOD,
  CLIENT_COMMUNICATION_REASON,
  CLIENT_COMMUNICATION_STATUS,
  DIAGNOSTIC_ORDER_STATUS,
  DOJO_NOTIFICATION_TYPE,
  DOJO_PAYMENT_STATUS,
  EMPLOYEE_ROLE,
  FILE_MIME_TYPE,
  HEALTH_PLAN_ALLOWANCE_ITEM_TYPE,
  HEALTH_PLAN_ALLOWANCE_PERIOD_TYPE,
  HEALTH_PLAN_ALLOWANCE_STATUS,
  HEALTH_PLAN_ALLOWANCE_TYPE,
  HEALTH_PLAN_BILLING_PERIOD_TYPE,
  HEALTH_PLAN_STATUS,
  HEALTH_PLAN_SUBSCRIBER_TYPE,
  HEALTH_PLAN_SUBSCRIPTION_STATUS,
  INSURANCE_CLAIMS_STATUS,
  LUPA_ERROR_KEY,
  PAYMENT_METHOD,
  PAYMENT_STATUS,
  PERMISSION_CATEGORY,
  PET_MEDICAL_RECORD_CATEGORY,
  PET_SCHEDULE_TYPE,
  PRODUCTS_SUPPLIER,
  PRODUCT_CATEGORY_TYPE,
  PRODUCT_MEDICAL_CATEGORY_TYPE,
  STORE_CATEGORY,
  STORE_ONLINE_PAYMENT_METHOD,
} from './enums';
import { formatCurrency } from './i18n';
import { assertUnreachable } from './type-utils';

export const getAppointmentStatusLabel = (status: APPOINTMENT_STATUS) => {
  switch (status) {
    case APPOINTMENT_STATUS.REQUESTED:
      return 'New Request';
    case APPOINTMENT_STATUS.CONFIRMED:
      return 'Confirmed';
    case APPOINTMENT_STATUS.CHECKED_IN:
      return 'Checked In';
    case APPOINTMENT_STATUS.IN_PROGRESS:
      return 'In Progress';
    case APPOINTMENT_STATUS.READY_FOR_CHECKOUT:
      return 'Ready for Checkout';
    case APPOINTMENT_STATUS.COMPLETED:
      return '🔒 Completed';
    case APPOINTMENT_STATUS.NO_SHOW:
      return 'No Show';
    case APPOINTMENT_STATUS.CANCELLED:
      return 'Cancelled';
    default:
      return status;
  }
};

export const getAppointmentPaymentStatusLabel = (
  status: APPOINTMENT_PAYMENT_STATUS,
) => {
  switch (status) {
    case APPOINTMENT_PAYMENT_STATUS.PAID:
      return 'Paid';
    case APPOINTMENT_PAYMENT_STATUS.PARTIALLY_PAID:
      return 'Partially Paid';
    case APPOINTMENT_PAYMENT_STATUS.PENDING:
      return 'To Be Paid';
    case APPOINTMENT_PAYMENT_STATUS.DEPOSIT_ON_HOLD:
      return 'Deposit on Hold';
    default:
      return status;
  }
};

export const getProductCategoryLabel = (category: PRODUCT_CATEGORY_TYPE) => {
  switch (category) {
    case PRODUCT_CATEGORY_TYPE.NUTRITION:
      return 'Nutrition';
    case PRODUCT_CATEGORY_TYPE.GROOMING_CARE:
      return 'Grooming & Care';
    case PRODUCT_CATEGORY_TYPE.PET_ACCESSORIES:
      return 'Pet Accessories';
    case PRODUCT_CATEGORY_TYPE.GENERAL_MEDICATION:
      return 'General Medication';
    case PRODUCT_CATEGORY_TYPE.INJECTABLES:
      return 'Injectables';
    case PRODUCT_CATEGORY_TYPE.VACCINATION:
      return 'Vaccination';
    case PRODUCT_CATEGORY_TYPE.ANTI_PARASITIC:
      return 'Anti-Parasitic';
    case PRODUCT_CATEGORY_TYPE.CONSUMABLES:
      return 'Consumables';
    case PRODUCT_CATEGORY_TYPE.OTHER:
      return 'Other';
    default:
      return category;
  }
};

export const getProductMedicalCategoryLabel = (
  category: PRODUCT_MEDICAL_CATEGORY_TYPE,
) => {
  switch (category) {
    case PRODUCT_MEDICAL_CATEGORY_TYPE.POM:
      return 'POM';
    case PRODUCT_MEDICAL_CATEGORY_TYPE.POM_VPS:
      return 'POM-VPS';
    case PRODUCT_MEDICAL_CATEGORY_TYPE.POM_V:
      return 'POM-V';
    case PRODUCT_MEDICAL_CATEGORY_TYPE.OTC:
      return 'OTC';
    case PRODUCT_MEDICAL_CATEGORY_TYPE.NFA_VPS:
      return 'NFA-VPS';
    case PRODUCT_MEDICAL_CATEGORY_TYPE.AVM_GSL:
      return 'AVM-GSL';
    case PRODUCT_MEDICAL_CATEGORY_TYPE.SAES:
      return 'SAES';
    case PRODUCT_MEDICAL_CATEGORY_TYPE.OTHER:
      return 'Other';
    default:
      return category;
  }
};

export const getSupplierNameLabel = (name: PRODUCTS_SUPPLIER) => {
  switch (name) {
    case PRODUCTS_SUPPLIER.NVS:
      return 'NVS';
    case PRODUCTS_SUPPLIER.MWI:
      return 'MWI';
    default:
      return name;
  }
};

export const getEmployeeRoleLabel = (role: EMPLOYEE_ROLE | Nullish<string>) => {
  if (role == null || role === '') {
    return '';
  }

  switch (role) {
    case EMPLOYEE_ROLE.ADMINISTRATIVE:
      return 'Administrative';
    case EMPLOYEE_ROLE.CARETAKER:
      return 'Caretaker';
    case EMPLOYEE_ROLE.DIRECTOR:
      return 'Director';
    case EMPLOYEE_ROLE.GROOMER:
      return 'Groomer';
    case EMPLOYEE_ROLE.MANAGER:
      return 'Manager';
    case EMPLOYEE_ROLE.NURSE:
      return 'Nurse';
    case EMPLOYEE_ROLE.PCA:
      return 'PCA';
    case EMPLOYEE_ROLE.RCVS_ADVANCED_PRACTITIONER:
      return 'RCVS Advanced Practitioner';
    case EMPLOYEE_ROLE.RCVS_SPECIALIST:
      return 'RCVS Specialist';
    case EMPLOYEE_ROLE.RECEPTIONIST:
      return 'Receptionist';
    case EMPLOYEE_ROLE.STUDENT_NURSE:
      return 'Student Nurse';
    case EMPLOYEE_ROLE.TRAINER:
      return 'Trainer';
    case EMPLOYEE_ROLE.VETERINARIAN:
      return 'Veterinarian';
    case EMPLOYEE_ROLE.VETERINARY_STAFF:
      return 'Veterinary Staff';
    case EMPLOYEE_ROLE.VETERINARY_TECHNICIAN:
      return 'Veterinary Technician';
    case EMPLOYEE_ROLE.WALKER:
      return 'Walker';
    default:
      return capitalize(role);
  }
};

export const getPaymentMethodLabel = (method: PAYMENT_METHOD | string) => {
  switch (method) {
    case PAYMENT_METHOD.CASH:
      return 'Cash';
    case PAYMENT_METHOD.CARD:
      return 'Credit/Debit Card';
    case PAYMENT_METHOD.ONLINE:
      return 'Online Payment';
    case PAYMENT_METHOD.BANK_TRANSFER:
      return 'Bank Transfer';
    case PAYMENT_METHOD.CREDIT:
      return 'Client Credit';
    case PAYMENT_METHOD.DOJO:
      return 'Dojo Terminal';
    case PAYMENT_METHOD.INSURANCE:
      return 'Insurance';
    case PAYMENT_METHOD.PAID_BY_STORE:
      return 'Paid for by store';
    case PAYMENT_METHOD.OTHER:
      return 'Other';
    default:
      return method;
  }
};

export const getFileTypeLabel = (mimeType: FILE_MIME_TYPE): string => {
  switch (mimeType) {
    case FILE_MIME_TYPE.PDF:
      return 'PDF';
    case FILE_MIME_TYPE.PNG:
      return 'PNG';
    case FILE_MIME_TYPE.JPG:
    case FILE_MIME_TYPE.JPEG:
      return 'JPEG';
    case FILE_MIME_TYPE.GIF:
      return 'GIF';
    case FILE_MIME_TYPE.BMP:
      return 'BMP';
    case FILE_MIME_TYPE.WEBP:
      return 'WebP';
    case FILE_MIME_TYPE.MP4:
      return 'MP4';
    case FILE_MIME_TYPE.WEBM:
      return 'WebM';
    case FILE_MIME_TYPE.MP3:
      return 'MP3';
    case FILE_MIME_TYPE.WAV:
      return 'WAV';
    case FILE_MIME_TYPE.OGG:
      return 'OGG';
    case FILE_MIME_TYPE.TXT:
      return 'Text';
    case FILE_MIME_TYPE.HTML:
      return 'HTML';
    case FILE_MIME_TYPE.CSS:
      return 'CSS';
    case FILE_MIME_TYPE.JSON:
      return 'JSON';
    case FILE_MIME_TYPE.XML:
      return 'XML';
    case FILE_MIME_TYPE.ZIP:
      return 'ZIP';
    case FILE_MIME_TYPE.GZIP:
      return 'GZIP';
    case FILE_MIME_TYPE.CSV:
      return 'CSV';
    case FILE_MIME_TYPE.TSV:
      return 'TSV';
    default:
      return 'Unknown';
  }
};

export const getStoreCategoryLabel = (category: STORE_CATEGORY | string) => {
  switch (category) {
    case STORE_CATEGORY.VET:
      return 'Veterinary';
    case STORE_CATEGORY.GROOMING:
      return 'Grooming';
    case STORE_CATEGORY.WALKING:
      return 'Walking';
    case STORE_CATEGORY.BOARDING:
      return 'Boarding';
    case STORE_CATEGORY.DAY_CARE:
      return 'Day Care';
    case STORE_CATEGORY.TRAINING:
      return 'Training';
    case STORE_CATEGORY.SITTING:
      return 'Sitting';
    case STORE_CATEGORY.PET_SHOP:
      return 'Pet Shop';
    case STORE_CATEGORY.ADOPTION:
      return 'Adoption';
    case STORE_CATEGORY.SHELTER_RESCUE:
      return 'Shelter & Rescue';
    case STORE_CATEGORY.CEMETERY:
      return 'Cemetery';
    case STORE_CATEGORY.OTHER:
      return 'Other';
    default:
      return category;
  }
};

export const getPermissionCategoryLabel = (
  category: PERMISSION_CATEGORY | string,
) => {
  switch (category) {
    case PERMISSION_CATEGORY.STAFF_SETTINGS:
      return 'Staff Settings';
    case PERMISSION_CATEGORY.APPOINTMENTS_SETTINGS:
      return 'Appointments Settings';
    case PERMISSION_CATEGORY.BOOKING_SETTINGS:
      return 'Booking Settings';
    case PERMISSION_CATEGORY.BUSINESS_SETTINGS:
      return 'Business Settings';
    case PERMISSION_CATEGORY.INTEGRATIONS_SETTINGS:
      return 'Integrations Settings';
    case PERMISSION_CATEGORY.LUPA_SETTINGS:
      return 'Lupa Settings';
    case PERMISSION_CATEGORY.ROTA:
      return 'Rota';
    case PERMISSION_CATEGORY.ANALYTICS:
      return 'Analytics';
    case PERMISSION_CATEGORY.CHAT:
      return 'Chat';
    case PERMISSION_CATEGORY.DISCOUNTS:
      return 'Discounts';
    case PERMISSION_CATEGORY.INVENTORY:
      return 'Inventory';
    default:
      return category;
  }
};

export const getPetMedicalRecordCategoryLabel = (
  category: PET_MEDICAL_RECORD_CATEGORY | string,
) => {
  switch (category) {
    case PET_MEDICAL_RECORD_CATEGORY.ALLERGY:
      return 'Allergy';
    case PET_MEDICAL_RECORD_CATEGORY.ILLNESS:
      return 'Illness';
    case PET_MEDICAL_RECORD_CATEGORY.INJURY:
      return 'Injury';
    case PET_MEDICAL_RECORD_CATEGORY.INFECTION:
      return 'Infection';
    case PET_MEDICAL_RECORD_CATEGORY.ANTI_PARASITIC:
      return 'Anti-Parasitic';
    case PET_MEDICAL_RECORD_CATEGORY.VACCINATION:
      return 'Vaccination';
    case PET_MEDICAL_RECORD_CATEGORY.OTHER:
      return 'Other';
    default:
      return category ?? 'Other';
  }
};

export const getInsuranceClaimsStatusLabel = (
  status: INSURANCE_CLAIMS_STATUS | string | undefined,
) => {
  switch (status) {
    case INSURANCE_CLAIMS_STATUS.DRAFT:
      return 'Draft';
    case INSURANCE_CLAIMS_STATUS.INITIATED:
      return 'Initiated';
    case INSURANCE_CLAIMS_STATUS.INVALID:
      return 'Invalid';
    case INSURANCE_CLAIMS_STATUS.DECLINED:
      return 'Declined';
    case INSURANCE_CLAIMS_STATUS.NULLIFIED:
      return 'Nullified';
    case INSURANCE_CLAIMS_STATUS.PROCESSING:
      return 'Processing';
    case INSURANCE_CLAIMS_STATUS.SETTLED:
      return 'Settled';
    case INSURANCE_CLAIMS_STATUS.READY_FOR_PROCESSING:
      return 'Submitted';
    default:
      throw new Error(`Unknown VetEnvoy claim status: ${status}`);
  }
};

export const getAppointmentTypeLabel = (
  type: APPOINTMENT_TYPE | string | undefined,
): string => {
  switch (type) {
    case APPOINTMENT_TYPE.ADMIN:
      return 'Admin';
    case APPOINTMENT_TYPE.CONSULT:
      return 'Consultation';
    case APPOINTMENT_TYPE.DAYCARE:
      return 'Daycare';
    case APPOINTMENT_TYPE.DENTAL:
      return 'Dental';
    case APPOINTMENT_TYPE.DIAGNOSTICS:
      return 'Diagnostics';
    case APPOINTMENT_TYPE.EUTHANASIA:
      return 'Euthanasia';
    case APPOINTMENT_TYPE.GROOMING:
      return 'Grooming';
    case APPOINTMENT_TYPE.GROUP_TRAINING:
      return 'Group Training';
    case APPOINTMENT_TYPE.GROUP_WALKING:
      return 'Group Walking';
    case APPOINTMENT_TYPE.HEALTH:
      return 'Health Check';
    case APPOINTMENT_TYPE.HOSPITAL:
      return 'Hospital';
    case APPOINTMENT_TYPE.IN_HOME:
      return 'In-Home Visit';
    case APPOINTMENT_TYPE.INDIVIDUAL_TRAINING:
      return 'Individual Training';
    case APPOINTMENT_TYPE.INDIVIDUAL_WALKING:
      return 'Individual Walking';
    case APPOINTMENT_TYPE.NURSE:
      return 'Nursing Care';
    case APPOINTMENT_TYPE.OTHER:
      return 'Other';
    case APPOINTMENT_TYPE.OVERNIGHT:
      return 'Overnight Stay';
    case APPOINTMENT_TYPE.SURGICAL:
      return 'Surgical Procedure';
    case APPOINTMENT_TYPE.VACCINATION:
      return 'Vaccination';
    default:
      if (typeof type === 'string') {
        return type.charAt(0).toUpperCase() + type.slice(1).toLowerCase();
      }
      return 'Unknown Appointment Type';
  }
};

export const getErrorKeysLabel = (
  key: Nullish<LUPA_ERROR_KEY>,
  defaultMessage = 'Something went wrong',
) => {
  switch (key) {
    case LUPA_ERROR_KEY.INVALID_PHONE_NUMBER:
      return 'Invalid phone number';
    case LUPA_ERROR_KEY.CLIENT_EXISTS:
      return 'Client with this phone number or email already exists';
    default:
      return defaultMessage;
  }
};

export const getCompanyPaymentMethodLabel = (
  type: Nullish<STORE_ONLINE_PAYMENT_METHOD>,
  amount: Nullish<number>,
) => {
  if (type == null || amount == null) {
    return '-';
  }

  switch (type) {
    case STORE_ONLINE_PAYMENT_METHOD.FIXED_AMOUNT:
      return formatCurrency(amount);
    case STORE_ONLINE_PAYMENT_METHOD.PERCENTAGE:
      return `${amount}%`;
    default:
      return '-';
  }
};

export const getDiagnosticOrdersStatusLabel = (
  status: DIAGNOSTIC_ORDER_STATUS,
): string => {
  switch (status) {
    case DIAGNOSTIC_ORDER_STATUS.CREATING:
      return 'Creating...';
    case DIAGNOSTIC_ORDER_STATUS.CREATED:
      return 'Created';
    case DIAGNOSTIC_ORDER_STATUS.ORDERED:
      return 'Ordered';
    case DIAGNOSTIC_ORDER_STATUS.WAITING_FOR_SAMPLE:
      return 'Waiting For Sample';
    case DIAGNOSTIC_ORDER_STATUS.SAMPLE_RECEIVED:
      return 'Sample Received';
    case DIAGNOSTIC_ORDER_STATUS.RUNNING:
      return 'Running';
    case DIAGNOSTIC_ORDER_STATUS.PARTIAL:
      return 'Partially complete';
    case DIAGNOSTIC_ORDER_STATUS.COMPLETE:
      return 'Complete';
    case DIAGNOSTIC_ORDER_STATUS.CANCELLED:
      return 'Cancelled';
    default:
      assertUnreachable(status);
      return status;
  }
};

export const getDiagnosticOrderStatusIsInProgressOrComplete = (
  status: DIAGNOSTIC_ORDER_STATUS,
): boolean => {
  // Returns true iff the order does not require further user attention to progress
  switch (status) {
    case DIAGNOSTIC_ORDER_STATUS.ORDERED:
    case DIAGNOSTIC_ORDER_STATUS.WAITING_FOR_SAMPLE:
    case DIAGNOSTIC_ORDER_STATUS.SAMPLE_RECEIVED:
    case DIAGNOSTIC_ORDER_STATUS.RUNNING:
    case DIAGNOSTIC_ORDER_STATUS.PARTIAL:
    case DIAGNOSTIC_ORDER_STATUS.COMPLETE:
      return true;
    case DIAGNOSTIC_ORDER_STATUS.CREATING:
    case DIAGNOSTIC_ORDER_STATUS.CREATED:
    case DIAGNOSTIC_ORDER_STATUS.CANCELLED:
      return false;
    default:
      assertUnreachable(status);
      return false;
  }
};

export const getDiagnosticProviderTypeShortname = (
  type: DIAGNOSTIC_PROVIDER_TYPE,
): string => {
  switch (type) {
    case DIAGNOSTIC_PROVIDER_TYPE.IDEXX_VETCONNECTPLUS_STAGING:
      return 'IDEXX VetConnect+ (Staging)';
    case DIAGNOSTIC_PROVIDER_TYPE.IDEXX_VETCONNECTPLUS_PROD:
      return 'IDEXX VetConnect+';
    case DIAGNOSTIC_PROVIDER_TYPE.VETSCAN_STAGING:
      return 'VETSCAN (Staging)';
    case DIAGNOSTIC_PROVIDER_TYPE.VETSCAN_PROD:
      return 'VETSCAN';
    default:
      assertUnreachable(type);
      return type;
  }
};

export function createFileSizeLabel(bytes: number): string {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(2))} ${sizes[i]}`;
}

export const getClientCommunicationStatusLabel = (
  status: CLIENT_COMMUNICATION_STATUS,
): string => {
  switch (status) {
    case CLIENT_COMMUNICATION_STATUS.NOT_SENT:
      return 'Not Sent';
    case CLIENT_COMMUNICATION_STATUS.SENT:
      return 'Sent';
    case CLIENT_COMMUNICATION_STATUS.FAILED:
      return 'Failed';
    default:
      assertUnreachable(status);
      return status;
  }
};

export const getClientCommunicationMethodLabel = (
  status: CLIENT_COMMUNICATION_METHOD,
): string => {
  switch (status) {
    case CLIENT_COMMUNICATION_METHOD.EMAIL:
      return 'Email';
    case CLIENT_COMMUNICATION_METHOD.SMS:
      return 'SMS';
    case CLIENT_COMMUNICATION_METHOD.PUSH_NOTIFICATION:
      return 'App Notification';
    default:
      assertUnreachable(status);
      return status;
  }
};

export const getClientCommunicationReasonLabel = (
  reason: CLIENT_COMMUNICATION_REASON,
): string => {
  switch (reason) {
    case CLIENT_COMMUNICATION_REASON.PET_SCHEDULE_REMINDER:
      return 'Pet Schedule Reminder';
    case CLIENT_COMMUNICATION_REASON.BOOKING_REMINDER:
      return 'Booking Reminder';
    case CLIENT_COMMUNICATION_REASON.BOOKING_UDPATE:
      return 'Booking Update';
    case CLIENT_COMMUNICATION_REASON.BOOKING_CONFIRMATION:
      return 'Booking Confirmation';
    case CLIENT_COMMUNICATION_REASON.BOOKING_REQUESTED:
      return 'Booking Requested';
    case CLIENT_COMMUNICATION_REASON.CHAT_MESSAGE_NOTIFICATION:
      return 'Chat Message Notification';
    case CLIENT_COMMUNICATION_REASON.CORRECTION_NOTIFICATION:
      return 'Correction Notification';
    case CLIENT_COMMUNICATION_REASON.PRESCRIPTION_READY_FOR_PICKUP:
      return 'Prescription Ready For Pickup';
    case CLIENT_COMMUNICATION_REASON.MANUAL_APPOINTMENT_EMAIL:
      return 'Manual Appointment Email';
    case CLIENT_COMMUNICATION_REASON.MANUAL_INVOICE_REMINDER:
      return 'Manual Invoice Reminder';
    default:
      assertUnreachable(reason);
      return reason;
  }
};

export const getDojoPaymentStatusLabel = (
  status: DOJO_PAYMENT_STATUS,
): string => {
  switch (status) {
    case DOJO_PAYMENT_STATUS.AUTHORIZED:
      return 'Payment authorized';
    case DOJO_PAYMENT_STATUS.CANCEL_REQUESTED:
      return 'Cancelling payment';
    case DOJO_PAYMENT_STATUS.CANCELED:
      return 'Payment canceled';
    case DOJO_PAYMENT_STATUS.CAPTURED:
      return 'Payment successful';
    case DOJO_PAYMENT_STATUS.DECLINED:
      return 'Payment declined';
    case DOJO_PAYMENT_STATUS.EXPIRED:
      return 'Payment expired';
    case DOJO_PAYMENT_STATUS.INITIATE_REQUESTED:
      return 'Initiate requested';
    case DOJO_PAYMENT_STATUS.INITIATED:
      return 'Payment initiated';
    case DOJO_PAYMENT_STATUS.REFUNDED:
      return 'Payment refunded';
    case DOJO_PAYMENT_STATUS.REVERSED:
      return 'Payment reversed';
    case DOJO_PAYMENT_STATUS.SIGNATURE_VERIFICATION_REJECTED:
      return 'Signature rejected';
    case DOJO_PAYMENT_STATUS.SIGNATURE_VERIFICATION_ACCEPTED:
      return 'Signature accepted';
    case DOJO_PAYMENT_STATUS.SIGNATURE_VERIFICATION_REQUIRED:
      return 'Verification required';
    default:
      return status;
  }
};

export function getDojoNotificationLabel(
  notificationType: DOJO_NOTIFICATION_TYPE,
): string {
  switch (notificationType) {
    case DOJO_NOTIFICATION_TYPE.APPROVED:
      return 'Payment approved';
    case DOJO_NOTIFICATION_TYPE.DECLINED:
      return 'Payment declined';
    case DOJO_NOTIFICATION_TYPE.INSERT_CARD:
      return 'Insert card';
    case DOJO_NOTIFICATION_TYPE.ENTER_PIN:
      return 'Enter PIN';
    case DOJO_NOTIFICATION_TYPE.PLEASE_WAIT:
      return 'Please wait';
    case DOJO_NOTIFICATION_TYPE.PRESENT_CARD:
      return 'Present card';
    case DOJO_NOTIFICATION_TYPE.PRESENT_ONLY_ONE_CARD:
      return 'Present only one card';
    case DOJO_NOTIFICATION_TYPE.REMOVE_CARD:
      return 'Remove card';
    case DOJO_NOTIFICATION_TYPE.CARD_UNSUPPORTED:
      return 'Card not supported';
    default:
      return notificationType; // Return the enum value if no meaningful status is defined
  }
}

export const getPaymentStatusLabel = (status: PAYMENT_STATUS): string => {
  switch (status) {
    case PAYMENT_STATUS.AUTHORIZED:
      return 'Authorized';
    case PAYMENT_STATUS.CANCELLED:
      return 'Cancelled';
    case PAYMENT_STATUS.CAPTURED:
      return 'Success';
    case PAYMENT_STATUS.COMPLETED:
      return 'Success';
    case PAYMENT_STATUS.FAILED:
      return 'Failed';
    case PAYMENT_STATUS.PENDING:
      return 'Pending';
    case PAYMENT_STATUS.PROCESSING:
      return 'Processing';
    case PAYMENT_STATUS.REFUNDED:
      return 'Refunded';
    case PAYMENT_STATUS.VOIDED:
      return 'Voided';
    default:
      return status;
  }
};
export const getHealthPlanAllowanceTypeLabel = (
  type: HEALTH_PLAN_ALLOWANCE_TYPE,
) => {
  switch (type) {
    case HEALTH_PLAN_ALLOWANCE_TYPE.PERCENTAGE:
      return 'Discount';
    case HEALTH_PLAN_ALLOWANCE_TYPE.FIXED_AMOUNT:
      return 'Fixed Amount';
    default:
      return type;
  }
};

export const getHealthPlanBillingPeriodTypeLabel = (
  type: HEALTH_PLAN_BILLING_PERIOD_TYPE,
) => {
  switch (type) {
    case HEALTH_PLAN_BILLING_PERIOD_TYPE.ANNUALLY:
      return 'Annually';
    case HEALTH_PLAN_BILLING_PERIOD_TYPE.MONTHLY:
      return 'Monthly';
    default:
      return type;
  }
};

export const getHealthPlanAllowanceItemTypeLabel = (
  type: HEALTH_PLAN_ALLOWANCE_ITEM_TYPE,
) => {
  switch (type) {
    case HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.PRODUCT_CATEGORY:
      return 'Product Category';
    case HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.SERVICE_CATEGORY:
      return 'Service Category';
    case HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.INDIVIDUAL_PRODUCT:
      return 'Individual Product';
    case HEALTH_PLAN_ALLOWANCE_ITEM_TYPE.INDIVIDUAL_SERVICE:
      return 'Individual Service';
    default:
      return type;
  }
};

export const getHealthPlanAllowancePeriodTypeLabel = (
  type: HEALTH_PLAN_ALLOWANCE_PERIOD_TYPE,
) => {
  switch (type) {
    case HEALTH_PLAN_ALLOWANCE_PERIOD_TYPE.ANNUALLY:
      return 'Annually';
    case HEALTH_PLAN_ALLOWANCE_PERIOD_TYPE.MONTHLY:
      return 'Monthly';
    default:
      return type;
  }
};

export const getHealthPlanSubscriberTypeLabel = (
  type: HEALTH_PLAN_SUBSCRIBER_TYPE,
) => {
  switch (type) {
    case HEALTH_PLAN_SUBSCRIBER_TYPE.CLIENT:
      return 'Client';
    case HEALTH_PLAN_SUBSCRIBER_TYPE.PET:
      return 'Pet';
    default:
      return type;
  }
};

export const getHealthPlanStatusLabel = (status: HEALTH_PLAN_STATUS) => {
  switch (status) {
    case HEALTH_PLAN_STATUS.ACTIVE:
      return 'Active';
    case HEALTH_PLAN_STATUS.CANCELLED:
      return 'Cancelled';
    default:
      return status;
  }
};

export const getHealthPlanAllowanceStatusLabel = (
  status: HEALTH_PLAN_ALLOWANCE_STATUS,
) => {
  switch (status) {
    case HEALTH_PLAN_ALLOWANCE_STATUS.ACTIVE:
      return 'Active';
    case HEALTH_PLAN_ALLOWANCE_STATUS.ARCHIVED:
      return 'Archived';
    default:
      return status;
  }
};

export const getHealthPlanSubscriptionStatusLabel = (
  status: HEALTH_PLAN_SUBSCRIPTION_STATUS,
) => {
  switch (status) {
    case HEALTH_PLAN_SUBSCRIPTION_STATUS.ACTIVE:
      return 'Active';
    case HEALTH_PLAN_SUBSCRIPTION_STATUS.CANCELLED:
      return 'Cancelled';
    default:
      return status;
  }
};

export const getPetScheduleTypeLabel = (type: PET_SCHEDULE_TYPE): string => {
  switch (type) {
    case PET_SCHEDULE_TYPE.PRESCRIPTION_REFILL:
      return 'Prescription Refill';
    case PET_SCHEDULE_TYPE.VACCINATION:
      return 'Vaccination';
    case PET_SCHEDULE_TYPE.ANTI_PARASITIC:
      return 'Anti-Parasitic';
    case PET_SCHEDULE_TYPE.OTHER_PREVENTIVE:
      return 'Other Preventive';
    default:
      assertUnreachable(type);
      return type;
  }
};
