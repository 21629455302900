import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import { getSupabase } from '~/lib/supabase';
import { globalSingleton } from '~/singletons/globalSingleton';

import { useAuth } from '../hooks/use-auth';
import { paths } from '../paths';

const REFRESH_THRESHOLD = 6 * 60; // 6 mins in seconds
const CHECK_TOKEN_VALIDITY_INTERVAL = 5 * 60 * 1000; // 5 mins in ms

interface AuthGuardProps {
  children: ReactNode;
}

export const AuthGuard: React.FC<AuthGuardProps> = ({ children }) => {
  const { isAuthenticated, isInitialized, signOut } = useAuth();
  const [isTokenValid, setIsTokenValid] = useState(false);

  const redirectToLogin = useCallback(() => {
    const searchParams = new URLSearchParams({
      returnTo: window.location.pathname,
    }).toString();
    const href = `${paths.auth.login}?${searchParams}`;
    window.location.href = href;
  }, []);

  const checkTokenValidity = useCallback(async () => {
    if (!isInitialized || !isAuthenticated) {
      return;
    }
    const supabase = await getSupabase();

    try {
      const {
        data: { session },
      } = await supabase.auth.getSession();

      if (session) {
        const sessionExpiresAt = session.expires_at; // in seconds
        const currentTime = Math.floor(Date.now() / 1000);
        const timeUntilExpiry = (sessionExpiresAt ?? 0) - currentTime;

        // Expired or expiring soon (5 minutes)
        if (timeUntilExpiry < REFRESH_THRESHOLD) {
          const { data, error } = await supabase.auth.refreshSession();

          if (error) {
            setIsTokenValid(false);
            throw error;
          }

          if (!data.session) {
            setIsTokenValid(false);
            throw new Error('Failed to refresh token');
          }

          console.info('Token refreshed');
          globalSingleton.accessToken = data.session.access_token;
          setIsTokenValid(true);
        } else {
          setIsTokenValid(true);
        }
      } else {
        setIsTokenValid(false);
        // No session, redirect to login
        redirectToLogin();
      }
    } catch (error) {
      console.error('Error checking token validity:', error);
      await signOut();
      redirectToLogin();
    }
  }, [isInitialized, isAuthenticated, signOut, redirectToLogin]);

  useEffect(() => {
    if (isInitialized && isAuthenticated) {
      checkTokenValidity();
    } else if (isInitialized && !isAuthenticated) {
      redirectToLogin();
    }
  }, [checkTokenValidity, redirectToLogin, isAuthenticated, isInitialized]);

  useEffect(() => {
    const intervalId = setInterval(
      checkTokenValidity,
      CHECK_TOKEN_VALIDITY_INTERVAL,
    );

    return () => clearInterval(intervalId);
  }, [checkTokenValidity]);

  if (!isTokenValid) {
    return null;
  }

  return children;
};
