import React from 'react';

import {
  Box,
  IconButton,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import { IconArrowRight, IconEdit, IconPaw } from '@tabler/icons-react';
import { differenceInMinutes, format } from 'date-fns';

import { paths } from '../../paths';
import { getImagePublicUrl } from '../../utils/get-image-public-url';
import { getEmployeeRoleLabel } from '../../utils/get-labels';
import { RouterLink } from '../RouterLink';
import { Scrollbar } from '../Scrollbar';
import { StatusPill } from '../StatusPill';
import StyledTableRow from '../StyledTableRow';
import TableRowsSkeleton from '../TableRowsSkeleton';
import FallbackAvatar from '../ui/FallbackAvatar';

interface Appointment {
  id: string;
  title: string;
  start: string;
  end: string;
  status: string;
  payment_status: string;
  pet: {
    id: string;
    name: string;
    avatar_url: Nullish<string>;
    species: string;
  };
  client: {
    id: string;
    full_name: Nullish<string>;
  };
  employees: Array<{
    employee: {
      id: string;
      full_name: string;
      role: string;
      avatar_url: Nullish<string>;
    };
  }>;
}

interface AppointmentsListTableProps {
  count?: number;
  items: Appointment[];
  onPageChange?: (event: unknown, newPage: number) => void;
  onRowsPerPageChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  page?: number;
  rowsPerPage?: number;
  isEditable?: boolean;
  pagination?: boolean;
  isLoading?: boolean;
  onClickEdit?: (appointment: Appointment) => void;
  rowsPerPageOptions?: number[];
}

export const AppointmentsListTable: React.FC<AppointmentsListTableProps> = ({
  count = 0,
  items = [],
  onPageChange,
  onRowsPerPageChange,
  page = 0,
  rowsPerPage = 25,
  isEditable = true,
  pagination = true,
  isLoading,
  onClickEdit,
  rowsPerPageOptions = [25, 50, 100],
}) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Scrollbar>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Pet</TableCell>
              <TableCell>Employees</TableCell>
              <TableCell width={140}>Date</TableCell>
              <TableCell align='center'>Payment Status</TableCell>
              <TableCell align='center'>Appointment Status</TableCell>
              <TableCell align='right' sx={{ minWidth: isEditable ? 104 : 50 }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoading && (
              <TableRowsSkeleton numRows={rowsPerPage} numCols={8} />
            )}
            {!isLoading &&
              items.map((appointment) => {
                const date = format(
                  new Date(appointment.start),
                  'dd/MM/yy HH:mm',
                );

                return (
                  <StyledTableRow hover key={appointment.id}>
                    <TableCell>
                      <Stack direction='column'>
                        <Link
                          color='inherit'
                          component={RouterLink}
                          href={paths.appointments.details(appointment.id)}
                          variant='subtitle2'
                        >
                          {appointment.title}
                        </Link>
                      </Stack>
                    </TableCell>

                    <TableCell
                      sx={{
                        minWidth: 120,
                        paddingRight: 0,
                      }}
                    >
                      <Stack direction='column' spacing={1}>
                        <Stack
                          key={appointment.pet.id}
                          alignItems='center'
                          direction='row'
                          spacing={1}
                        >
                          <FallbackAvatar
                            src={getImagePublicUrl(appointment.pet.avatar_url)}
                            fallbackIcon={<IconPaw color={deepPurple.A400} />}
                            size={42}
                          />

                          <Stack direction='column' maxWidth={120}>
                            <Link
                              color='inherit'
                              component={RouterLink}
                              href={paths.pets.details(appointment.pet.id)}
                              variant='subtitle2'
                            >
                              {appointment.pet.name}
                            </Link>
                            <Typography
                              color='text.secondary'
                              variant='subtitle2'
                            >
                              {appointment.pet.species}
                            </Typography>
                            <Link
                              color='inherit'
                              component={RouterLink}
                              href={paths.clients.details(
                                appointment.client.id,
                              )}
                              variant='subtitle2'
                              sx={{
                                display: 'inline-block',
                                maxWidth: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {appointment.client.full_name}
                            </Link>
                          </Stack>
                        </Stack>
                      </Stack>
                    </TableCell>

                    <TableCell>
                      <Stack direction='column' spacing={1}>
                        {appointment.employees.map(({ employee }) => (
                          <Stack
                            key={employee.id}
                            alignItems='center'
                            direction='row'
                            spacing={1}
                          >
                            <FallbackAvatar
                              src={getImagePublicUrl(employee.avatar_url)}
                              name={employee.full_name}
                              size={42}
                            />
                            <div>
                              <Link
                                color='inherit'
                                component={RouterLink}
                                href={paths.employees.details(employee.id)}
                                variant='subtitle2'
                              >
                                {employee.full_name}
                              </Link>
                              <Typography
                                color='text.secondary'
                                variant='body2'
                              >
                                {getEmployeeRoleLabel(employee.role)}
                              </Typography>
                            </div>
                          </Stack>
                        ))}
                      </Stack>
                    </TableCell>

                    <TableCell>
                      <Stack direction='column'>
                        <Typography variant='subtitle2'>{date}</Typography>
                        <Typography color='text.secondary' variant='body2'>
                          {`${differenceInMinutes(
                            new Date(appointment.end),
                            new Date(appointment.start),
                          )} mins`}
                        </Typography>
                      </Stack>
                    </TableCell>

                    <TableCell align='center'>
                      <StatusPill
                        status={appointment.payment_status}
                        type='appointment_payment_status'
                        size='small'
                      />
                    </TableCell>

                    <TableCell align='center'>
                      <StatusPill
                        status={appointment.status}
                        type='appointment'
                        size='small'
                      />
                    </TableCell>

                    <TableCell align='right' sx={{ paddingRight: 0 }}>
                      {isEditable && (
                        <IconButton
                          onClick={() => onClickEdit?.(appointment)}
                          data-testid='button-edit-appointment'
                        >
                          <IconEdit size={20} />
                        </IconButton>
                      )}
                      <IconButton
                        LinkComponent={RouterLink}
                        href={paths.appointments.details(appointment.id)}
                        data-testid='button-open-appointment'
                      >
                        <IconArrowRight size={20} />
                      </IconButton>
                    </TableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </Scrollbar>

      {pagination && onPageChange && (
        <TablePagination
          component='div'
          count={count}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      )}
    </Box>
  );
};
