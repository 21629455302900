import { P, match } from 'ts-pattern';

const discreteUnits = [
  'unit(s)',
  'tablet(s)',
  'capsule(s)',
  'sachet(s)',
  'drop(s)',
  'pipette(s)',
] as const;

const continuousUnits = ['ml', 'mg', 'g', 'kg', 'l'] as const;

export const DEFAULT_UNITS = [...discreteUnits, ...continuousUnits];

function shouldRenderUnitAsWords(unit: string): boolean | null {
  const unitsToRenderAsWords: readonly string[] = discreteUnits;
  return unitsToRenderAsWords.includes(unit);
}

export const formatUnit = ({
  unit,
  quantity,
}: {
  unit: string;
  quantity: number;
}) => {
  const { singular, plural } = match(unit)
    .with(P.string.endsWith('(s)'), () => {
      const baseUnit = unit.replace(/\(s\)$/, '');
      return {
        singular: baseUnit,
        plural: `${baseUnit}s`,
      };
    })
    .otherwise(() => ({
      singular: unit,
      plural: unit,
    }));

  return quantity === 1 ? singular : plural;
};

const numberToWords: Record<number, string> = {
  1: 'ONE',
  2: 'TWO',
  3: 'THREE',
  4: 'FOUR',
  5: 'FIVE',
  6: 'SIX',
  7: 'SEVEN',
  8: 'EIGHT',
  9: 'NINE',
  10: 'TEN',
  11: 'ELEVEN',
  12: 'TWELVE',
};

export const formatNumberAsWords = (quantity: number) =>
  numberToWords[quantity] ?? quantity.toString();

export const formatQuantityAndUnit = ({
  unit,
  quantity,
}: {
  unit: string;
  quantity: number;
}) => {
  const formattedUnit = formatUnit({ unit, quantity });
  const formattedQuantity = shouldRenderUnitAsWords(unit)
    ? formatNumberAsWords(quantity)
    : quantity;
  return `${formattedQuantity} ${formattedUnit}`;
};
