import { addDays, subDays } from 'date-fns';
import { atom, createStore } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import { DOJO_NOTIFICATION_TYPE, DOJO_PAYMENT_STATUS } from '~/utils/enums';
import { CALENDAR_COLOUR_TYPE, CALENDAR_VIEW_TYPE } from '~/utils/local-enums';

export const lastLoadedStoreIdAtom = atomWithStorage<Nullable<string>>(
  'lastLoadedStore',
  null,
  undefined,
  { getOnInit: true },
);

export const mainCalendarSelectedEmployeesAtom = atomWithStorage<
  { id: string }[]
>('mainCalendarSelectedEmployees', [], undefined, { getOnInit: true });

export const mainCalendarSelectedTab = atomWithStorage<Nullable<string>>(
  'mainCalendarSelectedTab',
  null,
  undefined,
  { getOnInit: true },
);

export const mainCalendarDateAtom = atom(new Date());

export const mainCalendarColourType = atomWithStorage<CALENDAR_COLOUR_TYPE>(
  'mainCalendarColourType',
  CALENDAR_COLOUR_TYPE.EMPLOYEE,
  undefined,
  { getOnInit: true },
);

export const mainCalendarDateRangeAtom = atom({
  startDate: null as Date | null,
  endDate: null as Date | null,
});

export const mainCalendarViewAtom = atomWithStorage<CALENDAR_VIEW_TYPE>(
  'mainCalendarView',
  window.innerWidth < 600
    ? CALENDAR_VIEW_TYPE.TIME_GRID_DAY
    : CALENDAR_VIEW_TYPE.RESOURCE_TIME_GRID_WEEK,
  undefined,
  { getOnInit: true },
);

export const mainCalendarScrollsAtom = atom({
  horizontal: 0,
  vertical: 0,
});

// https://github.com/fullcalendar/fullcalendar/issues/5026
export const displayMainCalendarAtom = atom(true);

// export const flowChartDatesAtom = atom({
//   start: new Date(),
//   end: addDays(new Date(), 7),
// });

export const flowChartDatesAtom = atomWithStorage<{
  start: Date | string;
  end: Date | string;
}>(
  'flowChartDatesAtom',
  {
    start: new Date(),
    end: addDays(new Date(), 7),
  },
  undefined,
  { getOnInit: true },
);

export const hospitalizationDateAtom = atom(new Date());

export const inventoryBasketAtom = atom([]);

export const mainDrawerOpenAtom = atomWithStorage(
  'mainDrawerOpen',
  true,
  undefined,
  { getOnInit: true },
);

export const appointmentsTableAtom = atom({
  filters: {
    query: undefined,
    chipFilters: {},
  },
  page: 0,
  rowsPerPage: 50,
  sortBy: 'start',
  sortDir: 'desc',
});

export const unpaidAppointmentsTableAtom = atom({
  filters: {
    query: undefined,
    chipFilters: {},
  },
  page: 0,
  rowsPerPage: 50,
  sortBy: 'start',
  sortDir: 'desc',
});

export const diagnosticsTableAtom = atom({
  filters: {
    query: undefined,
    chipFilters: {},
  },
  page: 0,
  rowsPerPage: 50,
  sortBy: 'start',
  sortDir: 'desc',
});

export const clientCommsTableAtom = atom({
  filters: {
    query: undefined,
    chipFilters: {},
  },
  page: 0,
  rowsPerPage: 50,
  sortBy: 'start',
  sortDir: 'desc',
});

export const analyticsDatesAtom = atom<{
  start: Date | string;
  end: Date | string;
}>({
  start: subDays(new Date(), 30),
  end: new Date(),
});

export const dojoPaymentStatusPerIntentAtoms = atom<
  Record<
    string,
    {
      sessionId: string;
      latestNotification: DOJO_NOTIFICATION_TYPE;
      status: DOJO_PAYMENT_STATUS;
    }
  >
>({});

export const settingsSelectedTab = atomWithStorage<Nullable<string>>(
  'settingsSelectedTab',
  null,
  undefined,
  { getOnInit: true },
);

export const sharedAtomStore = createStore();
