import React from 'react';

import { AuthGuard } from '../guards/AuthGuard';

export const withAuthGuard = (Component: React.ComponentType<any>) => {
  const WithAuthGuard: React.FC<any> = (props) => {
    return (
      <AuthGuard>
        <Component {...props} />
      </AuthGuard>
    );
  };
  return WithAuthGuard;
};
