/* eslint-disable react/jsx-no-constructed-context-values */
import React, { ReactNode, useCallback, useEffect, useReducer } from 'react';

import { getSupabase } from '../../lib/supabase';
import { globalSingleton } from '../../singletons/globalSingleton';
import { AuthContext, initialState } from './AuthContext';

enum ActionType {
  INITIALIZE = 'INITIALIZE',
  SIGN_IN = 'SIGN_IN',
  SIGN_OUT = 'SIGN_OUT',
}

type Action =
  | {
      type: ActionType.INITIALIZE;
      payload: { isAuthenticated: boolean; user: any | null };
    }
  | { type: ActionType.SIGN_IN; payload: { user: any } }
  | { type: ActionType.SIGN_OUT };

const handlers: {
  [key in ActionType]: (state: any, action: Action) => any;
} = {
  [ActionType.INITIALIZE]: (state, action) => {
    if (action.type === ActionType.INITIALIZE) {
      const { isAuthenticated, user } = action.payload;
      globalSingleton.accessToken = user?.session?.access_token || '';
      globalSingleton.userId = user?.id || '';

      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        user,
      };
    }
  },
  [ActionType.SIGN_IN]: (state, action) => {
    if (action.type === ActionType.SIGN_IN) {
      const { user } = action.payload;
      globalSingleton.accessToken = user.session?.access_token || '';
      globalSingleton.userId = user.id || '';

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
  },
  [ActionType.SIGN_OUT]: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
};

const reducer = (state: any, action: Action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    const supabase = await getSupabase();

    const {
      data: { user },
    } = await supabase.auth.getUser();

    const {
      data: { session },
    } = await supabase.auth.getSession();

    dispatch({
      type: ActionType.INITIALIZE,
      payload: {
        isAuthenticated: !!user && !!session,
        user: user && session ? { ...user, session } : null,
      },
    });
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    const unsubscribeFnPromise = getSupabase().then((supabase) => {
      const { data: authListener } = supabase.auth.onAuthStateChange(
        async (event, session) => {
          if (event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED') {
            // Token has been refreshed or user has signed in
            globalSingleton.accessToken = session?.access_token ?? '';
            globalSingleton.userId = session?.user?.id ?? '';
          }
          if (event === 'SIGNED_OUT') {
            dispatch({ type: ActionType.SIGN_OUT });
          }
        },
      );

      return () => {
        authListener?.subscription?.unsubscribe();
      };
    });

    return () => {
      unsubscribeFnPromise.then((unsubscribeFn) => unsubscribeFn());
    };
  }, []);

  const signOut = useCallback(async () => {
    const supabase = await getSupabase();
    await supabase.auth.signOut();
    dispatch({
      type: ActionType.SIGN_OUT,
    });
  }, [dispatch]);

  const signIn = useCallback(
    async (email: string, password: string) => {
      const supabase = await getSupabase();
      const {
        data: { user },
        error,
      } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) {
        throw error;
      }

      const {
        data: { session },
      } = await supabase.auth.getSession();

      dispatch({
        type: ActionType.SIGN_IN,
        payload: {
          user: {
            ...user,
            session,
          },
        },
      });
    },
    [dispatch],
  );

  const signInWithGoogle = useCallback(async () => {
    try {
      const supabase = await getSupabase();
      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: `${window.location.origin}`,
        },
      });

      if (error) {
        console.error('Error signing in: ', error);
      }

      const {
        data: { user },
      } = await supabase.auth.getUser();
      const {
        data: { session },
      } = await supabase.auth.getSession();

      dispatch({
        type: ActionType.SIGN_IN,
        payload: {
          user: {
            ...user,
            session,
          },
        },
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        signIn,
        signInWithGoogle,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
