import React from 'react';

import '@fontsource/poppins/100.css';
import '@fontsource/poppins/200.css';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/800.css';
import '@fontsource/poppins/900.css';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as Sentry from '@sentry/react';
import { enGB } from 'date-fns/locale';
import { enableMapSet as immerEnableMapSet } from 'immer';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';

import App from './App';
import { AuthProvider } from './contexts/auth/AuthProvider';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { API_ENDPOINT, IS_DEV } from './utils/networkUtils';

if (!IS_DEV) {
  ReactGA.initialize('G-XM7LZKG9RE');

  Sentry.init({
    dsn: 'https://7505b62ca90ade09743d30c01e601747@o4507455239553024.ingest.de.sentry.io/4507455244337232',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.captureConsoleIntegration({
        levels: ['error'],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', API_ENDPOINT],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: IS_DEV ? 'development' : 'production',
  });
}

immerEnableMapSet();

const root = ReactDOM.createRoot(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  document.getElementById('root') as HTMLElement,
);

const AppWithProviders = () => (
  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
    <AuthProvider>
      <App />
    </AuthProvider>
  </LocalizationProvider>
);

root.render(
  IS_DEV ? (
    <React.StrictMode>
      <AppWithProviders />
    </React.StrictMode>
  ) : (
    <AppWithProviders />
  ),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.debug))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
