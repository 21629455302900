import { match } from 'ts-pattern';
import { z } from 'zod';

import { PRESCRIPTION_STATUS } from '~/utils/enums';
import { zIsoDateString } from '~/utils/time-utils';
import { formatNumberAsWords, formatQuantityAndUnit } from '~/utils/units';

export const employeeStubValidationSchema = z.object({
  id: z.string().uuid(),
  full_name: z.string(),
  avatar_url: z.string().nullable(),
  rcvs_registration_number: z.string().nullish(),
});

// Dispenses
export const prescriptionDispenseValidationSchema = z.object({
  id: z.string().uuid(),
  is_cancelled: z.boolean(),
  authorisation: z
    .object({
      given_by_employee: employeeStubValidationSchema,
      given_at: z.string().datetime({ offset: true }),
    })
    .nullable(),
  billing: z
    .object({
      billing_product_id: z.string().uuid(),
    })
    .nullable(),
});

export type PrescriptionDispense = z.infer<
  typeof prescriptionDispenseValidationSchema
>;

export const prescriptionDispenseActionsSchema = z
  .object({
    cancel: z.object({}),
    authorise: z.object({}),
    link_to_billing_product: z.object({
      id: z.string().uuid(),
    }),
  })
  .partial();

export type PrescriptionDispenseActions = z.infer<
  typeof prescriptionDispenseActionsSchema
>;

// Prescription
export const prescriptionTimeUnits = [
  'hours',
  'days',
  'weeks',
  'months',
  'years',
] as const;

const StructuredPrescriptionDosageSchema = z.object({
  type: z.literal('structured'),
  quantity: z.number().min(1, 'Quantity must be at least 1'),
  frequency: z.object({
    intervalNumber: z.number().min(1),
    intervalUnit: z.enum(prescriptionTimeUnits),
    timesPerInterval: z.number().min(1),
  }),
  startDate: zIsoDateString,
  duration: z.discriminatedUnion('type', [
    z.object({
      type: z.literal('one-off'),
    }),
    z.object({
      type: z.literal('for-duration'),
      durationNumber: z.number().min(1),
      durationUnit: z.enum(prescriptionTimeUnits),
    }),
    z.object({
      type: z.literal('until'),
      until: zIsoDateString,
    }),
    z.object({
      type: z.literal('ongoing'),
    }),
  ]),
  additionalInstructions: z.string().optional(),
});

const FreeformPrescriptionDosageSchema = z.object({
  type: z.literal('freeform'),
  instructions: z.string(),
});

export const PrescriptionDosageSchema = z.discriminatedUnion('type', [
  StructuredPrescriptionDosageSchema,
  FreeformPrescriptionDosageSchema,
]);

export type StructuredPrescriptionDosage = z.infer<
  typeof StructuredPrescriptionDosageSchema
>;
export type FreeformPrescriptionDosage = z.infer<
  typeof FreeformPrescriptionDosageSchema
>;
export type PrescriptionDosage = z.infer<typeof PrescriptionDosageSchema>;

export const prescriptionSchema = z.object({
  id: z.string().uuid(),
  pet_id: z.string().uuid(),
  client_id: z.string().uuid(),
  product_name: z.string(),
  is_prescribed_only: z.boolean(),
  prescriber_employee_id: z.string().uuid(),

  quantity: z
    .number({
      message: 'Quantity is required',
    })
    .min(1, 'Quantity must be at least 1'),
  unit: z.string({
    message: 'Unit is required',
  }),

  dosage_specification: PrescriptionDosageSchema,

  refill_limit: z.number().min(0, 'Refill limit must be at least 0'),
  refills_permitted_until: z.string().nullable(),

  status: z.nativeEnum(PRESCRIPTION_STATUS),

  next_refill_due: z.string().nullish(),
  source_product_id: z.string().uuid().nullish(),
});

export const prescriptionUpsertSchema = z.intersection(
  z.object({
    id: z.string().uuid().optional(),
  }),
  prescriptionSchema.omit({ id: true }),
);

export type Prescription = z.infer<typeof prescriptionSchema>;
export type PrescriptionUpsert = z.infer<typeof prescriptionUpsertSchema>;

export const prescriptionAndDispensesUpsertSchema = z.object({
  prescription: prescriptionUpsertSchema,
  dispenses: z.object({
    modified: z.record(z.string().uuid(), prescriptionDispenseActionsSchema),
    created: z.array(prescriptionDispenseActionsSchema),
  }),
  formOptions: z.object({
    notifyReadyForPickup: z.boolean().nullable(),
  }),
});

export type PrescriptionAndDispensesUpsert = z.infer<
  typeof prescriptionAndDispensesUpsertSchema
>;

// Rendering utils
export const formatFrequency = ({
  timesPerInterval,
  intervalNumber,
  intervalUnit,
}: StructuredPrescriptionDosage['frequency']) => {
  const timesPerIntervalFormatted = match(timesPerInterval)
    .with(1, () => '')
    .with(2, () => 'twice ')
    .otherwise(
      () => `${formatNumberAsWords(timesPerInterval).toLowerCase()} times `,
    );

  const intervalNumberFormatted =
    intervalNumber === 1
      ? ''
      : `${formatNumberAsWords(intervalNumber).toLowerCase()} `;

  const intervalUnitFormatted =
    intervalNumber === 1 ? intervalUnit.replace(/s$/, '') : intervalUnit;

  return `${timesPerIntervalFormatted}every ${intervalNumberFormatted}${intervalUnitFormatted}`;
};

export function formatDuration(
  duration: StructuredPrescriptionDosage['duration'],
) {
  if (duration.type === 'one-off') {
    return null;
  }

  if (duration.type === 'for-duration') {
    const durationUnitFormatted =
      duration.durationNumber === 1
        ? duration.durationUnit.replace(/s$/, '')
        : duration.durationUnit;
    return `for ${duration.durationNumber} ${durationUnitFormatted}`;
  }

  if (duration.type === 'until') {
    const formattedDate = new Date(duration.until).toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
    return `until ${formattedDate}`;
  }

  if (duration.type === 'ongoing') {
    return 'ongoing';
  }
}

export const formatPrescriptionDosage = ({
  prescription: { dosage_specification, unit },
}: {
  prescription: Pick<Prescription, 'dosage_specification' | 'unit'>;
}) => {
  if (dosage_specification.type === 'freeform') {
    return dosage_specification.instructions;
  }

  const formattedAmount = formatQuantityAndUnit({
    quantity: dosage_specification.quantity,
    unit,
  });

  const formattedFrequency = formatFrequency(dosage_specification.frequency);

  const formattedDuration = formatDuration(dosage_specification.duration);

  const formattedDosage = `Give ${formattedAmount} ${formattedFrequency}${formattedDuration ? ` ${formattedDuration}` : ''}`;

  if (dosage_specification.additionalInstructions) {
    return `${formattedDosage}. ${dosage_specification.additionalInstructions}`;
  }
  return formattedDosage;
};
