import React, { useMemo, useState } from 'react';

import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Paper,
  Popper,
  Stack,
  SxProps,
  TextField,
  Theme,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteProps,
} from '@mui/material/Autocomplete';
import { IconChevronDown } from '@tabler/icons-react';
import { FormikProps } from 'formik';

import { getFieldHelperText } from '~/utils/formik-utils';

function highlightMatch(text: string, match: string) {
  const parts = text.split(new RegExp(`(${match})`, 'gi'));
  return (
    <span>
      {parts.map((part, index) =>
        part.toLowerCase() === match.toLowerCase() ? (
          <Typography
            key={index}
            variant='subtitle2'
            component='span'
            fontWeight='bold'
            color='primary'
          >
            {part}
          </Typography>
        ) : (
          part
        ),
      )}
    </span>
  );
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  [`& .MuiPaper-root`]: {
    maxWidth: '100%',
    mt: 1,
    boxShadow: theme.shadows[16],
  },
}));

type TrpcQueryFunction = {
  useQuery: any; // Could not find a good way to get the type from TRPC ;(
};

interface AutocompleteSearchConfig<T> {
  ItemType: T;
  Multiple?: boolean;
  DisableClearable?: boolean;
  FreeSolo?: boolean;
}

// @ts-ignore
interface AutocompleteSearchProps<Config extends AutocompleteSearchConfig<any>>
  extends Omit<
    AutocompleteProps<
      Config['ItemType'],
      Config['Multiple'],
      Config['DisableClearable'],
      Config['FreeSolo']
    >,
    'renderInput' | 'options'
  > {
  trpcFunction: TrpcQueryFunction;
  label: string;
  getOptionLabel: (
    option: Config['FreeSolo'] extends true
      ? Config['ItemType'] | string
      : Config['ItemType'],
  ) => string;
  value: Config['Multiple'] extends true
    ? Partial<Config['ItemType']>[]
    : Config['FreeSolo'] extends true
      ? Partial<Config['ItemType']> | string | null | undefined
      : Partial<Config['ItemType']> | null | undefined;
  onSelect: (
    option: Config['Multiple'] extends true
      ? Config['ItemType'][]
      : Config['DisableClearable'] extends true
        ? Config['ItemType']
        : Config['ItemType'] | null,
  ) => void;
  extraTrpcInput?: { [key: string]: any };
  freeSoloOnTextChange?: (text: string) => void;
  name?: string;
  formik?: FormikProps<any>;
  sx?: SxProps<Theme>;
  inputPropsSx?: SxProps<Theme>;
  isFilter?: boolean;
  size?: 'small' | 'medium';
  testIdPrefix?: string;
  extraOptionText?: string;
  handleExtraOptionOnClick?: () => void;
  showErrorBeforeTouched?: boolean;
  showTooltip?: boolean;
}

export function AutocompleteSearch<
  Config extends AutocompleteSearchConfig<{ id: string }>,
>({
  trpcFunction,
  label,
  getOptionLabel,
  value,
  onSelect,
  extraTrpcInput,
  freeSoloOnTextChange,
  name,
  formik,
  sx,
  inputPropsSx,
  isFilter = false,
  size,
  testIdPrefix,
  extraOptionText,
  handleExtraOptionOnClick,
  showErrorBeforeTouched = false,
  showTooltip = false,
  ...autocompleteProps
}: AutocompleteSearchProps<Config>) {
  const [inputValue, setInputValue] = useState('');
  const [inputChanged, setInputChanged] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const query = trpcFunction.useQuery(
    { query: inputChanged ? inputValue : '', ...extraTrpcInput },
    {
      keepPreviousData: true,
      enabled: isOpen,
      retry: false,
    },
  );

  const handleInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    newInputValue: string,
    reason: AutocompleteInputChangeReason,
  ) => {
    if (reason === 'reset') {
      return;
    }

    if (event != null) {
      // This means the user is typing
      setInputChanged(true);
    }

    if (autocompleteProps.freeSolo) {
      freeSoloOnTextChange?.(newInputValue);
    }

    setInputValue(newInputValue);
  };

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    value:
      | Config['ItemType']
      | NonNullable<string | Config['ItemType']>
      | (string | Config['ItemType'])[]
      | null,
    reason: AutocompleteChangeReason,
  ) => {
    if (reason === 'blur') {
      return;
    }

    if (value == null) {
      // @ts-ignore
      onSelect(null);
      return;
    }

    if (autocompleteProps.multiple) {
      // @ts-ignore
      onSelect(value as Config['ItemType'][]);
      return;
    }

    // @ts-ignore
    onSelect(value as Config['ItemType']);
  };

  const getOptionLabelOrString = (option: Config['ItemType'] | string) => {
    if (typeof option === 'string') {
      return option;
    }

    return getOptionLabel(option);
  };

  const valueLabel = useMemo(() => {
    if (!value) {
      return '';
    }

    if (autocompleteProps.multiple) {
      return (value as Config['ItemType'][])
        .map((value) => getOptionLabelOrString(value))
        .join(', ');
    }

    return getOptionLabelOrString(value as unknown as Config['ItemType']);
  }, [value]);

  const onClickExtraOption = () => {
    setIsOpen(false);
    setInputValue('');

    handleExtraOptionOnClick?.();
  };

  const errorMessage =
    formik != null && name != null
      ? getFieldHelperText(formik, name, showErrorBeforeTouched)
      : null;

  return (
    <Autocomplete
      {...autocompleteProps}
      fullWidth
      // @ts-ignore
      value={value}
      open={isOpen}
      onOpen={() => {
        setIsOpen(true);
      }}
      onClose={() => {
        setInputChanged(false);
        setIsOpen(false);
      }}
      options={query.data ?? []}
      getOptionLabel={getOptionLabelOrString}
      renderInput={(params) => (
        <Tooltip
          title={valueLabel}
          open={(isFilter || showTooltip) && isTooltipOpen && !isOpen}
          placement='top'
          onOpen={() => {
            setIsTooltipOpen(true);
          }}
          onClose={() => {
            setIsTooltipOpen(false);
          }}
        >
          <TextField
            {...params}
            label={label}
            error={Boolean(errorMessage)}
            helperText={errorMessage}
            onBlur={formik?.handleBlur}
            fullWidth
            variant='outlined'
            InputProps={{
              ...params.InputProps,
              sx: isFilter
                ? {
                    borderRadius: 4,
                    ...inputPropsSx,
                  }
                : inputPropsSx,
              style: {
                fontSize: 14,
              },
              endAdornment: (
                <>
                  {query.isFetching ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        </Tooltip>
      )}
      renderOption={(props, option) => {
        // @ts-ignore - This is a hack to get the subtitle
        const optionSubtitle = option.subtitle;

        return (
          <Stack
            component='li'
            {...props}
            // @ts-ignore
            key={option.id}
            direction='column'
            style={{
              alignItems: 'flex-start',
              flexGrow: 1,
              paddingLeft: 2,
              paddingRight: 2,
              paddingTop: 4,
              paddingBottom: 4,
            }}
          >
            <Typography
              variant='subtitle2'
              style={{
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {highlightMatch(getOptionLabelOrString(option), inputValue)}
            </Typography>

            {optionSubtitle && (
              <Typography
                variant='caption'
                color='text.secondary'
                style={{
                  paddingLeft: 2,
                  paddingRight: 2,
                }}
              >
                {optionSubtitle}
              </Typography>
            )}
          </Stack>
        );
      }}
      onInputChange={handleInputChange}
      onChange={handleChange}
      loading={query.isFetching}
      loadingText='Loading...'
      noOptionsText='No options'
      popupIcon={<IconChevronDown />}
      blurOnSelect
      PaperComponent={({ children }) => {
        return (
          <Paper>
            <Box>{children}</Box>
            {handleExtraOptionOnClick && (
              <Box
                sx={{
                  justifyContent: 'flex-start',
                  p: 1,
                  borderTop: '1px solid',
                  borderColor: 'divider',
                }}
              >
                <Button
                  color='primary'
                  size='small'
                  fullWidth
                  onMouseDown={onClickExtraOption}
                  data-testid={`${testIdPrefix}-extra-option-btn`}
                >
                  {extraOptionText}
                </Button>
              </Box>
            )}
          </Paper>
        );
      }}
      PopperComponent={StyledPopper}
      sx={
        isFilter
          ? {
              flexGrow: 1,
              minWidth: 180,
              maxWidth: 180,
              borderRadius: 4,
              marginTop: '4px',
              ...sx,
            }
          : sx
      }
      size={isFilter && size == null ? 'small' : size}
    />
  );
}
