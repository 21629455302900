import { z } from 'zod';

import {
  PRODUCT_CATEGORY_TYPE,
  PRODUCT_MEDICAL_CATEGORY_TYPE,
} from '~/utils/enums';

const productValidators = {
  product: z
    .object({
      category: z.nativeEnum(PRODUCT_CATEGORY_TYPE, {
        message: 'Category is required',
      }),
      medical_category: z
        .nativeEnum(PRODUCT_MEDICAL_CATEGORY_TYPE, {
          message: 'Medical Category is required',
        })
        .nullish(),
      name: z
        .string()
        .max(255, { message: 'Name must be shorter than 255 characters' })
        .min(1, 'Name is required'),
      item_code: z
        .string()
        .max(255, { message: 'Item code must be shorter than 255 characters' })
        .nullable(),
      description: z
        .string()
        .max(5000, {
          message: 'Description must be shorter than 5000 characters',
        })
        .nullish(),

      supplier: z.string().nullish(),
      supplier_product: z
        .object({
          id: z.string(),
          code: z.string(),
          description: z.string(),
        })
        .nullable(),

      has_subunit: z.boolean().nullable(),
      unit: z.string().nullable(),
      subunit_multiplier: z.number().optional(),
      subunit: z.string().nullable(),

      has_batches: z.boolean().optional(),
      quantity: z.number().optional(),
      measure_unit: z.string().nullish(),
      batches: z
        .array(
          z.object({
            batch_number: z.string().max(255),
            expiry_date: z
              .string()
              .datetime({
                offset: true,
                message: 'Expiry date is required',
              })
              .nullish(),
            quantity: z.number().min(1, 'Quantity must be at least 1'),
          }),
        )
        .optional(),

      price: z.number().min(0, 'Price must be a positive number'),
      vat_code: z.string().min(1, 'VAT Code is required'),
      procurement_cost: z
        .number()
        .min(0, 'Procurement cost must be a positive number'),
      vat_percentage: z
        .number()
        .min(0, 'VAT percentage must be a positive number'),
      margin: z.number().min(-100, 'Margin must be more than -100'),

      has_advanced_discounts: z.boolean(),
      discount_1: z.number().min(-100, 'Incorrect discount value'),
      discount_2: z.number().min(-100, 'Incorrect discount value'),
      discount_3: z.number().min(-100, 'Incorrect discount value'),

      has_fees: z.boolean(),
      dispensing_fee: z
        .number()
        .min(0, 'Dispensing fee must be a positive number'),

      minimum_stock_level: z.number().min(0).optional(),
      optimal_stock_level: z.number().min(0).optional(),
    })
    .superRefine((val, ctx) => {
      if (val.has_subunit) {
        if (!val.unit) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Unit is required when "Has Subunits" is selected',
            path: ['unit'],
          });
        }
        if (!val.subunit) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Subunit is required when "Has Subunits" is selected',
            path: ['subunit'],
          });
        }
        if (!val.subunit_multiplier || val.subunit_multiplier < 1) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message:
              'Subunit multiplier must be greater than or equal to 1 when "Has Subunits" is selected',
            path: ['subunit_multiplier'],
          });
        }
      }

      if (
        !val.has_batches &&
        (val.quantity === undefined || val.quantity < 0)
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            'Quantity must be greater than or equal to 0 when "Has Batches" is not selected',
          path: ['quantity'],
        });
      }

      if (val.measure_unit) {
        if (
          val.has_subunit &&
          val.measure_unit !== val.unit &&
          val.measure_unit !== val.subunit
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message:
              'Measure unit must be the same as the product unit or subunit',
            path: ['measure_unit'],
          });
        } else if (!val.has_subunit && val.measure_unit !== val.unit) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Measure unit must be the same as the product unit',
            path: ['measure_unit'],
          });
        }
      }

      if (val.has_batches && (!val.batches || val.batches.length === 0)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            'At least one batch is required when "Has Batches" is selected',
          path: ['batches'],
        });
      }
    }),
};

export default productValidators;
