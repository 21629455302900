import React from 'react';

import {
  Document,
  Image,
  Page,
  Path,
  Rect,
  StyleSheet,
  Svg,
  Text,
  View,
} from '@react-pdf/renderer';
import { format } from 'date-fns';

import { globalSingleton } from '~/singletons/globalSingleton';
import { getImagePublicUrl } from '~/utils/get-image-public-url';
import { getPDFDocumentTheme } from '~/utils/pdf-utils';
import {
  Prescription,
  formatPrescriptionDosage,
} from '~/validators/prescriptionValidators';

import registerFonts from '../pdf/fontRegistration';

registerFonts();

const HEADER_HEIGHT = 180;

interface WrittenPrescriptionPDFProps {
  prescription: Pick<
    Prescription,
    | 'product_name'
    | 'quantity'
    | 'unit'
    | 'dosage_specification'
    | 'prescriber_employee_id'
  >;
  prescriberName: string;
  prescriberRcvsRegistrationNumber: string | null;
  petName: string;
  clientName: string;
  clientAddress?: string;
}

export default function WrittenPrescriptionPDF({
  prescription,
  petName,
  clientName,
  clientAddress,
  prescriberName,
  prescriberRcvsRegistrationNumber,
}: WrittenPrescriptionPDFProps) {
  const { currentStore } = globalSingleton;
  const themeColors = getPDFDocumentTheme(currentStore?.color);

  return (
    <Document title='Prescription'>
      <Page
        size='A4'
        style={{ ...styles.page, backgroundColor: themeColors.lightColor }}
      >
        <Svg
          width='598'
          height={`${HEADER_HEIGHT + 30}`}
          viewBox={`0 0 598 ${HEADER_HEIGHT + 30}`}
          style={{ position: 'absolute' }}
        >
          <Rect width='100%' height='100%' fill={themeColors.darkColor} />
        </Svg>

        <View style={styles.main}>
          <View
            style={[styles.vstack, { height: HEADER_HEIGHT, marginTop: 12 }]}
          >
            <View style={styles.headerContent}>
              <View style={styles.headerLeft}>
                <Text style={styles.storeName}>{currentStore?.name}</Text>
                <Text style={styles.storeDetails}>
                  {currentStore?.address?.formatted_address}
                </Text>
                <Text style={styles.storeDetails}>{currentStore?.email}</Text>
                <Text style={styles.storeDetails}>{currentStore?.phone}</Text>
              </View>

              <Image
                src={getImagePublicUrl(currentStore?.logo_url)}
                style={styles.logo}
              />
            </View>

            <View style={styles.prescriptionTitle}>
              <Text style={styles.title}>Prescription</Text>

              <View style={styles.prescriptionDetails}>
                <View style={styles.detailItem}>
                  <Svg width='12' height='12' viewBox='0 0 12 12'>
                    <Path
                      d='M9.5 1H9V0.5C9 0.367392 8.94732 0.240215 8.85355 0.146447C8.75979 0.0526784 8.63261 0 8.5 0C8.36739 0 8.24021 0.0526784 8.14645 0.146447C8.05268 0.240215 8 0.367392 8 0.5V1H4V0.5C4 0.367392 3.94732 0.240215 3.85355 0.146447C3.75979 0.0526784 3.63261 0 3.5 0C3.36739 0 3.24021 0.0526784 3.14645 0.146447C3.05268 0.240215 3 0.367392 3 0.5V1H2.5C1.8372 1.00079 1.20178 1.26444 0.73311 1.73311C0.264441 2.20178 0.000793929 2.8372 0 3.5L0 9.5C0.000793929 10.1628 0.264441 10.7982 0.73311 11.2669C1.20178 11.7356 1.8372 11.9992 2.5 12H9.5C10.1628 11.9992 10.7982 11.7356 11.2669 11.2669C11.7356 10.7982 11.9992 10.1628 12 9.5V3.5C11.9992 2.8372 11.7356 2.20178 11.2669 1.73311C10.7982 1.26444 10.1628 1.00079 9.5 1Z'
                      fill='white'
                    />
                  </Svg>
                  <Text>{format(new Date(), 'dd MMMM yyyy')}</Text>
                </View>

                <View style={styles.detailItem}>
                  <Svg width='12' height='12' viewBox='0 0 12 12'>
                    <Path
                      d='M6 0C4.81331 0 3.65328 0.351894 2.66658 1.01118C1.67989 1.67047 0.910851 2.60754 0.456726 3.7039C0.00259972 4.80026 -0.11622 6.00666 0.115291 7.17054C0.346802 8.33443 0.918247 9.40353 1.75736 10.2426C2.59648 11.0818 3.66558 11.6532 4.82946 11.8847C5.99335 12.1162 7.19975 11.9974 8.2961 11.5433C9.39246 11.0892 10.3295 10.3201 10.9888 9.33342C11.6481 8.34673 12 7.18669 12 6C11.9983 4.40923 11.3656 2.88411 10.2407 1.75926C9.1159 0.634414 7.59077 0.00172054 6 0V0Z'
                      fill='white'
                    />
                  </Svg>
                  <Text>{prescriberName}</Text>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.whiteBox}>
            <Text style={styles.sectionTitle}>Patient Details</Text>
            <View style={styles.patientDetails}>
              <Text style={styles.patientDetail}>
                Name of animal: {petName}
              </Text>
              <Text style={styles.patientDetail}>
                Client Name: {clientName}
              </Text>
              {clientAddress && (
                <Text style={styles.patientDetail}>
                  Client Address: {clientAddress}
                </Text>
              )}
            </View>
            <Text style={styles.sectionTitle}>Medication Details</Text>
            <View style={styles.medicationInfo}>
              <Text style={styles.medicationName}>
                {prescription.product_name}
              </Text>
              <Text style={styles.medicationDetails}>
                Quantity: {prescription.quantity} {prescription.unit}
              </Text>
            </View>

            <Text style={styles.sectionTitle}>Instructions</Text>
            <Text style={styles.instructions}>
              {formatPrescriptionDosage({ prescription })}
            </Text>

            <View style={styles.footer}>
              <View style={styles.footerSection}>
                <Text style={styles.footerText}>
                  Prescribed by: {prescriberName}
                  {prescriberRcvsRegistrationNumber && (
                    <>
                      {'\n'}
                      RCVS number: {prescriberRcvsRegistrationNumber}
                    </>
                  )}
                </Text>
                <Text style={styles.footerText}>
                  {currentStore?.address?.formatted_address}
                </Text>
                <Text style={styles.footerText}>
                  Phone: {currentStore?.phone}
                </Text>
              </View>
              <View style={styles.footerSection}>
                <Text style={styles.footerText}>Staff Signature:</Text>
                <View style={styles.signatureLine} />
              </View>
            </View>
          </View>

          <View style={styles.poweredBy}>
            <Image src='/full-logo.png' style={styles.poweredByLogo} />
          </View>
        </View>
      </Page>
    </Document>
  );
}

const styles = StyleSheet.create({
  vstack: {
    flexDirection: 'column',
  },
  page: {
    flexDirection: 'column',
    color: 'white',
    fontSize: 12,
    flexGrow: 1,
    paddingHorizontal: 60,
    paddingVertical: 40,
    fontFamily: 'Poppins',
  },
  main: {
    width: '100%',
    flexDirection: 'column',
    flexGrow: 1,
  },
  headerContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderBottomWidth: 1,
    borderBottomColor: 'white',
    borderBottomStyle: 'solid',
    paddingBottom: 10,
  },
  headerLeft: {
    flexDirection: 'column',
    gap: 2,
    fontSize: 10,
  },
  headerRight: {
    flexDirection: 'column',
    gap: 2,
    fontSize: 10,
    alignItems: 'flex-end',
  },
  storeName: {
    fontSize: 12,
  },
  patientDetail: {
    fontSize: 10,
  },
  storeDetails: {
    fontSize: 10,
  },
  logo: {
    borderRadius: 40,
    width: 40,
    height: 40,
    position: 'absolute',
    top: -45,
    left: '50%',
    transform: 'translateX(-20)',
  },
  clientHeader: {
    fontSize: 12,
  },
  clientDetails: {
    fontSize: 10,
  },
  prescriptionTitle: {
    alignItems: 'center',
    marginTop: 8,
    fontSize: 10,
    gap: 8,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 14,
  },
  prescriptionDetails: {
    flexDirection: 'row',
    gap: 24,
  },
  detailItem: {
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
  },
  whiteBox: {
    width: '100%',
    backgroundColor: 'white',
    flexDirection: 'column',
    padding: 16,
    borderRadius: 24,
    color: '#1C2536',
    marginTop: 20,
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: 'semibold',
    color: '#313F5E',
    marginBottom: 8,
    marginTop: 16,
  },
  medicationInfo: {
    marginLeft: 10,
  },
  medicationName: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  medicationDetails: {
    fontSize: 11,
    marginBottom: 3,
  },
  patientDetails: {
    fontSize: 11,
    marginBottom: 3,
  },
  instructions: {
    fontSize: 12,
    marginLeft: 10,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
    borderTop: '1px solid #eceff1',
    paddingTop: 10,
  },
  footerSection: {
    width: '45%',
  },
  footerText: {
    fontSize: 10,
    marginBottom: 3,
  },
  signatureLine: {
    borderBottom: '1px solid #000',
    width: '100%',
    marginTop: 20,
  },
  poweredBy: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'absolute',
    bottom: -20,
    right: -36,
  },
  poweredByLogo: {
    height: 20,
  },
});
