import {
  APPOINTMENT_PAYMENT_STATUS,
  APPOINTMENT_STATUS,
  EMPLOYEE_PERMISSION_LEVEL,
  EMPLOYEE_STATUS,
  ORDER_ITEM_STATUS,
  ORDER_STATUS,
  PAYMENT_STATUS,
  STORE_INVOICE_PAYMENT_STATUS,
  STORE_INVOICE_STATUS,
} from './enums';

export const getAppointmentColor = (status: string) => {
  switch (status) {
    case APPOINTMENT_STATUS.REQUESTED:
      return 'warning';
    case APPOINTMENT_STATUS.CONFIRMED:
      return 'info';
    case APPOINTMENT_STATUS.CHECKED_IN:
    case APPOINTMENT_STATUS.IN_PROGRESS:
    case APPOINTMENT_STATUS.READY_FOR_CHECKOUT:
      return 'primary';
    case APPOINTMENT_STATUS.COMPLETED:
      return 'success';
    case APPOINTMENT_STATUS.NO_SHOW:
    case APPOINTMENT_STATUS.CANCELLED:
      return 'error';
    default:
      return 'primary';
  }
};

export const getEmployeeStatusColor = (status: EMPLOYEE_STATUS) => {
  switch (status) {
    case EMPLOYEE_STATUS.ACTIVE:
    case EMPLOYEE_STATUS.CONFIRMED:
    case EMPLOYEE_STATUS.WORKING:
      return 'success';
    case EMPLOYEE_STATUS.PENDING_VERIFICATION:
    case EMPLOYEE_STATUS.ON_HOLIDAYS:
      return 'info';
    case EMPLOYEE_STATUS.DELETED:
    case EMPLOYEE_STATUS.SICK:
      return 'error';
    default:
      return 'warning';
  }
};

export const getEmployeePermissionColor = (
  status: EMPLOYEE_PERMISSION_LEVEL,
) => {
  switch (status) {
    case EMPLOYEE_PERMISSION_LEVEL.ADMIN:
      return 'success';
    case EMPLOYEE_PERMISSION_LEVEL.CORE:
      return 'info';
    case EMPLOYEE_PERMISSION_LEVEL.SUPPORT:
      return 'warning';
    default:
      return 'warning';
  }
};

export const getAppointmentPaymentStatusColor = (
  status: APPOINTMENT_PAYMENT_STATUS,
) => {
  switch (status) {
    case APPOINTMENT_PAYMENT_STATUS.PAID:
      return 'success';
    case APPOINTMENT_PAYMENT_STATUS.PARTIALLY_PAID:
      return 'info';
    case APPOINTMENT_PAYMENT_STATUS.PENDING:
      return 'error';
    case APPOINTMENT_PAYMENT_STATUS.DEPOSIT_ON_HOLD:
      return 'info';
    default:
      return 'warning';
  }
};

export const getStoreInvoiceStatusColor = (status: STORE_INVOICE_STATUS) => {
  switch (status) {
    case STORE_INVOICE_STATUS.COMPLETED:
      return 'success';
    case STORE_INVOICE_STATUS.DRAFT:
      return 'info';
    case STORE_INVOICE_STATUS.ARCHIVED:
    default:
      return 'warning';
  }
};

export const getStoreInvoicePaymentStatusColor = (
  status: STORE_INVOICE_PAYMENT_STATUS,
) => {
  switch (status) {
    case STORE_INVOICE_PAYMENT_STATUS.PAID:
      return 'success';
    case STORE_INVOICE_PAYMENT_STATUS.UNPAID:
    case STORE_INVOICE_PAYMENT_STATUS.PARTIALLY_PAID:
      return 'info';
    default:
      return 'warning';
  }
};

export const getOrderStatusColor = (status: ORDER_STATUS) => {
  switch (status) {
    case ORDER_STATUS.PENDING:
      return 'warning';
    case ORDER_STATUS.COMPLETED:
      return 'success';
    case ORDER_STATUS.CANCELLED:
      return 'error';
    default:
      return 'primary';
  }
};

export const getOrderItemStatusColor = (status: ORDER_ITEM_STATUS) => {
  switch (status) {
    case ORDER_ITEM_STATUS.PENDING:
      return 'warning';
    case ORDER_ITEM_STATUS.RECEIVED:
      return 'info';
    case ORDER_ITEM_STATUS.PROCESSED:
      return 'success';
    default:
      return 'primary';
  }
};

export const getPaymentStatusColor = (status: PAYMENT_STATUS) => {
  switch (status) {
    case PAYMENT_STATUS.AUTHORIZED:
      return 'success';
    case PAYMENT_STATUS.CANCELLED:
      return 'error';
    case PAYMENT_STATUS.CAPTURED:
      return 'success';
    case PAYMENT_STATUS.COMPLETED:
      return 'success';
    case PAYMENT_STATUS.FAILED:
      return 'error';
    case PAYMENT_STATUS.PENDING:
      return 'info';
    case PAYMENT_STATUS.PROCESSING:
      return 'primary';
    case PAYMENT_STATUS.REFUNDED:
      return 'info';
    case PAYMENT_STATUS.VOIDED:
      return 'info';
    default:
      return 'error';
  }
};
