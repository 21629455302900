import React from 'react';

import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import ReactPDF, { pdf } from '@react-pdf/renderer';
import { IconChevronDown } from '@tabler/icons-react';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import z from 'zod';
import { toFormikValidate, toFormikValidationSchema } from 'zod-formik-adapter';

import { trpc } from '~/lib/trpc';
import { globalSingleton } from '~/singletons/globalSingleton';
import {
  PrescriptionDispenseActions,
  prescriptionDispenseActionsSchema,
  prescriptionDispenseValidationSchema,
  prescriptionUpsertSchema,
} from '~/validators/prescriptionValidators';

import DosageLabelsPDF from '../appointments/DosageLabelsPDF';
import WrittenPrescriptionPDF from '../appointments/WrittenPrescriptionPDF';
import DropdownMenuButton from '../ui/DropdownMenuButton';
import FormSaveButton from '../ui/form/FormSaveButton';
import PrescriptionFormDispenseSection from './PrescriptionFormDispenseSection';
import PrescriptionFormPrescriptionSection from './PrescriptionFormPrescriptionSection';

const WAGWORKS_STORE_ID = '593802bb-889f-4dd8-a318-c62bbbeb92b9';
export const getPrescriptionRefillsEnabled = (): boolean =>
  globalSingleton.isTestUser ||
  globalSingleton.currentStore?.id === WAGWORKS_STORE_ID;

export const prescriptionAndDispensesFormSchema = z.object({
  prescription: prescriptionUpsertSchema,
  dispenses: z.object({
    initial: z.array(prescriptionDispenseValidationSchema),
    modified: z.record(z.string().uuid(), prescriptionDispenseActionsSchema),
    created: z.array(prescriptionDispenseActionsSchema),
  }),
  formOptions: z.object({
    notifyReadyForPickup: z.boolean().nullable(),
  }),
});

export type PrescriptionAndDispensesFormDataType = z.infer<
  typeof prescriptionAndDispensesFormSchema
>;

export interface PrescriptionFormProps {
  initialValues: PrescriptionAndDispensesFormDataType;
  handleClose: () => void;

  billingProductContext?: {
    id: string;
    display_name: string;
  };
  onSaved: (args: {
    prescriptionId: string;
    updatesForParentForm?: {
      dispensesIdsToAddToContextBillingProduct: string[];
    };
  }) => Promise<void>;
}

/**
 * Where the parent form has unsaved new billing products, we need to pass
 * the dispense ids to the parent form so that it can update the billing product
 * with the dispense ids when the billing product is created.
 */
function getBillingProductContextUpdates({
  dispenseActions,
  billingProductContext,
}: {
  dispenseActions: Record<string, PrescriptionDispenseActions>;
  billingProductContext?: PrescriptionFormProps['billingProductContext'];
}) {
  if (billingProductContext == null) {
    return undefined;
  }

  const billingProductUpdatesToApply = Object.entries(dispenseActions).flatMap(
    ([dispenseId, actions]) => {
      const billingProductId = actions.link_to_billing_product?.id;
      return billingProductId ? [{ dispenseId, billingProductId }] : [];
    },
  );

  billingProductUpdatesToApply.forEach(({ billingProductId }) => {
    if (billingProductId !== billingProductContext.id) {
      throw new Error('Trying to link dispense to different billing product');
    }
  });

  return {
    dispensesIdsToAddToContextBillingProduct: billingProductUpdatesToApply.map(
      ({ dispenseId }) => dispenseId,
    ),
  };
}

export default function PrescriptionForm({
  initialValues,
  billingProductContext,
  onSaved,
  handleClose,
}: PrescriptionFormProps) {
  const petQuery = trpc.pets.getPet.useQuery(
    {
      petId: initialValues.prescription.pet_id,
    },
    {
      onError: () => {
        toast.error(`Error fetching pet details`);
      },
    },
  );

  const prescriberQuery = trpc.employees.getEmployee.useQuery(
    {
      employeeId: initialValues.prescription.prescriber_employee_id,
    },
    {
      onError: () => {
        toast.error(`Error fetching prescriber details`);
      },
    },
  );

  const trpcUtils = trpc.useUtils();
  const savePrescriptionMutation =
    trpc.prescriptions.upsertPrescription.useMutation({
      onSuccess: async (result) => {
        await onSaved({
          prescriptionId: result.prescription.id,
          updatesForParentForm: getBillingProductContextUpdates({
            dispenseActions: formik.values.dispenses.modified,
            billingProductContext,
          }),
        });

        if (result.notificationResult) {
          if (result.notificationResult === 'SENT') {
            toast.success('Notification sent to client');
          } else {
            toast.error('Notification failed to send');
          }
        }

        trpcUtils.pets.getPetMedicalSummary.invalidate();
        trpcUtils.prescriptions.invalidate();

        toast.success('Prescription saved');
        handleClose();
      },
      onError: (error) => {
        toast.error(`Error: ${error.message || 'Something went wrong!'}`);
      },
    });
  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues,
    validationSchema: toFormikValidationSchema(
      prescriptionAndDispensesFormSchema,
    ),
    validate: toFormikValidate(prescriptionAndDispensesFormSchema),
    onSubmit: async (values) => {
      await savePrescriptionMutation.mutateAsync(values);
    },
  });

  const onPrint = async (
    document: React.ReactElement<ReactPDF.DocumentProps>,
  ) => {
    const blob = await pdf(document).toBlob();
    const url = URL.createObjectURL(blob);
    const newTab = window.open(url, '_blank');
    if (newTab) {
      newTab.print();
    }
  };

  return (
    <Stack direction='column' spacing={2} px={1} width={700}>
      <PrescriptionFormPrescriptionSection formik={formik} />
      {getPrescriptionRefillsEnabled() && (
        <PrescriptionFormDispenseSection
          formik={formik}
          billingProductContext={billingProductContext}
        />
      )}

      <Stack
        direction='row'
        alignItems='center'
        spacing={2}
        justifyContent='flex-end'
      >
        <Button
          onClick={() => {
            formik.resetForm();
            handleClose();
          }}
        >
          Cancel
        </Button>

        {petQuery.isLoading || prescriberQuery.isLoading ? (
          <LoadingButton loading>Print</LoadingButton>
        ) : petQuery.isError || prescriberQuery.isError ? (
          <Button disabled>Can't print: error</Button>
        ) : (
          <DropdownMenuButton
            disabled={!formik.isValid}
            size='medium'
            variant='contained'
            options={[
              {
                name: 'Written Prescription',
                onClick: () =>
                  onPrint(
                    <WrittenPrescriptionPDF
                      prescription={formik.values.prescription}
                      petName={petQuery.data.name}
                      clientName={petQuery.data.owner[0].client.full_name}
                      clientAddress={
                        petQuery.data.owner[0].client.address?.formatted_address
                      }
                      prescriberName={prescriberQuery.data.full_name}
                      prescriberRcvsRegistrationNumber={
                        prescriberQuery.data.rcvs_registration_number
                      }
                    />,
                  ),
              },
              {
                name: 'Dosage Label',
                onClick: () =>
                  onPrint(
                    <DosageLabelsPDF
                      prescription={formik.values.prescription}
                      petName={petQuery.data.name}
                      clientName={petQuery.data.owner[0].client.full_name}
                      clientAddress={
                        petQuery.data.owner[0].client.address?.formatted_address
                      }
                      prescriberName={prescriberQuery.data.full_name}
                    />,
                  ),
              },
            ]}
            endIcon={<IconChevronDown size={16} />}
          >
            Print
          </DropdownMenuButton>
        )}

        <FormSaveButton
          formik={formik}
          loading={savePrescriptionMutation.isLoading}
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          Save
        </FormSaveButton>
      </Stack>
    </Stack>
  );
}
