import React from 'react';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  SxProps,
  Tooltip,
} from '@mui/material';
import { IconEdit } from '@tabler/icons-react';

type CardSectionProps = {
  title: string;
  onEdit?: () => void;
  actionButton?: React.ReactNode;
  editDisabled?: boolean;
  children: React.ReactNode;
  testIdPrefix?: string;
  sx?: SxProps;
  editTooltip?: string;
  elevation?: number;
};

export default function CardSection({
  title,
  onEdit,
  actionButton,
  editDisabled,
  children,
  testIdPrefix = '',
  sx,
  editTooltip = '',
  elevation,
}: CardSectionProps) {
  return (
    <Card elevation={elevation} sx={{ position: 'relative', ...sx }}>
      <CardHeader
        title={title}
        action={
          onEdit ? (
            <Tooltip title={editTooltip} arrow>
              <span>
                <IconButton
                  disabled={editDisabled}
                  onClick={onEdit}
                  data-testid={`${testIdPrefix}-card-header-edit-btn`}
                >
                  <IconEdit />
                </IconButton>
              </span>
            </Tooltip>
          ) : (
            actionButton
          )
        }
        titleTypographyProps={{
          variant: 'h6',
          color: '#37474f',
        }}
        sx={{ pb: onEdit ? 1 : 1.5, pt: 1.5 }}
      />
      <CardContent
        sx={{ pt: 0, mt: -2 }}
        data-testid={`${testIdPrefix}-card-content`}
      >
        <Box
          sx={{
            borderTopWidth: 4,
            borderTopStyle: 'solid',
            borderTopColor: 'rgba(124, 58, 237, 0.5)',
            width: 80,
            borderRadius: 8,
          }}
        />

        <Stack direction='column' mt={2}>
          {children}
        </Stack>
      </CardContent>
    </Card>
  );
}
