export type PDFColorScheme = {
  darkColor: string;
  lightColor: string;
  mainColor: string;
};

const getColorScheme = (color: string): PDFColorScheme => {
  switch (color) {
    case '#7C3AED':
      return {
        darkColor: '#2C105B',
        lightColor: '#F2F0F5',
        mainColor: '#7C3AED',
      };
    case '#3f51b5':
      return {
        darkColor: '#1A237E',
        lightColor: '#F2F0F5',
        mainColor: '#3f51b5',
      };
    case '#45DD82':
      return {
        darkColor: '#0D1E26',
        lightColor: '#F5F5F5',
        mainColor: '#45DD82',
      };
    case '#E95454':
      return {
        darkColor: '#36123C',
        lightColor: '#F2F0F5',
        mainColor: '#E95454',
      };
    case '#504B3A':
      return {
        darkColor: '#221F17',
        lightColor: '#F5F2F1',
        mainColor: '#504B3A',
      };
    default:
      return {
        darkColor: '#2C105B',
        lightColor: '#F2F0F5',
        mainColor: '#7C3AED',
      };
  }
};

export const getPDFDocumentTheme = (
  storeColor: Nullish<string>,
): PDFColorScheme => {
  if (!storeColor) {
    return {
      darkColor: '#2C105B',
      lightColor: '#F2F0F5',
      mainColor: '#7C3AED',
    };
  }

  return getColorScheme(storeColor);
};
