import React, { useMemo, useState } from 'react';

import {
  PetWeightRecord,
  PetWeightRecords,
} from '#types/database-custom.types';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { deepPurple, grey } from '@mui/material/colors';
import {
  IconCalendar,
  IconEdit,
  IconPlus,
  IconTrash,
} from '@tabler/icons-react';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { produce } from 'immer';
import { Props as ApexProps } from 'react-apexcharts';
import toast from 'react-hot-toast';
import * as Yup from 'yup';

import LupaDatePicker from '~/components/ui/LupaDatePicker';
import { COLORS } from '~/consts/colors';
import { trpc } from '~/lib/trpc';

import { updatePetProperties } from '../../api/mutations';
import { useDialog } from '../../hooks/use-dialog';
import { Chart } from '../Chart';
import DialogSection from '../ui/DialogSection';
import NumberInput from '../ui/NumberInput';

const CHART_OPTIONS: ApexProps['options'] = {
  chart: {
    type: 'area',
  },
  xaxis: {
    type: 'datetime',
  },
  tooltip: {
    y: {
      formatter: (value: number) => `${value} kgs`,
    },
  },
  yaxis: {
    show: false,
  },
};

const useInitialValues = (weights: PetWeightRecords | null) => {
  return useMemo(() => {
    return {
      weights: weights ?? [],
    };
  }, [weights]);
};

const validationSchema = Yup.object({
  weights: Yup.array()
    .of(
      Yup.object().shape({
        weight: Yup.number()
          .positive('Weight must be a positive number')
          .required('Weight is required'),
        weight_date: Yup.date().required('Date is required'),
      }),
    )
    .required('At least one weight entry is required'),
});

interface PetWeightUpsertProps {
  petId: string;
  weights: PetWeightRecords | null;
  onClose: () => void;
}

export default function PetWeightUpsert({
  petId,
  weights,
  onClose,
}: PetWeightUpsertProps) {
  const upsertWeightDialog = useDialog();

  const initialValues = useInitialValues(weights);

  const [editData, setEditData] = useState<PetWeightRecord>({
    weight: 0,
    weight_date: new Date().toISOString(),
  });

  const [editingIndex, setEditingIndex] = useState(-1);

  const weightsMutation = trpc.pets.updatePet.useMutation({
    onSuccess: ({ updatedPetId, data }) => {
      updatePetProperties(updatedPetId, { weights: data.weights });
      toast.success('Pet updated');
      formik.resetForm();
      onClose();
    },

    onError: () => {
      toast.error('Something went wrong!');
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        weightsMutation.mutate({
          petId,
          data: {
            weights: values.weights,
          },
        });
      } catch (err) {
        console.error(err);
        toast.error('Something went wrong!');
      }
    },
  });

  const handleAddOrEditWeight = async () => {
    const weightToValidate = {
      weight: editData.weight,
      weight_date: editData.weight_date,
    };

    try {
      await validationSchema.validateAt('weights[0]', {
        weights: [weightToValidate],
      });

      const updatedWeights = produce(
        formik.values.weights,
        (draftWeightData: PetWeightRecords) => {
          if (draftWeightData == null) {
            return;
          }

          if (editingIndex === -1) {
            draftWeightData.push(editData);
          } else {
            draftWeightData[editingIndex] = editData;
          }

          draftWeightData.sort(
            (a, b) =>
              new Date(b.weight_date).getTime() -
              new Date(a.weight_date).getTime(),
          );
        },
      );

      formik.setFieldValue('weights', updatedWeights);
      upsertWeightDialog.handleClose();
      setEditData({ weight: 0, weight_date: new Date().toISOString() });
      setEditingIndex(-1);
    } catch (error) {
      console.error('Validation error:', error);
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  const handleDelete = (idxToDelete: number) => {
    const updatedWeights = produce(formik.values.weights, (draft) => {
      draft.splice(idxToDelete, 1);
    });

    formik.setFieldValue('weights', updatedWeights);
  };

  const handleAddWeightClick = () => {
    setEditData({
      weight: 0,
      weight_date: new Date().toISOString(),
    });

    upsertWeightDialog.handleOpen();
  };

  const handleEditClick = (data: PetWeightRecord, index: number) => {
    setEditData({
      weight: data.weight,
      weight_date: data.weight_date,
    });

    setEditingIndex(index);
    upsertWeightDialog.handleOpen({ action: 'edit' });
  };

  const series = [
    {
      name: 'Weight',
      data: formik.values.weights.map((record) => [
        new Date(record.weight_date).getTime(),
        record.weight,
      ]),
      color: COLORS.main,
    },
  ];

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Typography variant='h6'>Weight Graph</Typography>
          <Chart height={300} options={CHART_OPTIONS} series={series} />
        </Grid>

        <Grid item xs={12} md={6}>
          <Stack
            direction='column'
            gap={1}
            sx={{
              maxHeight: 300,
              overflowY: 'auto',
            }}
          >
            {formik.values.weights.map((data, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    padding: 1,
                    color: index === 0 ? 'white' : grey[800],
                    backgroundColor: index === 0 ? deepPurple.A400 : grey[100],
                    borderRadius: 2,
                  }}
                >
                  <Stack direction='column'>
                    <Stack
                      direction='row'
                      gap={1}
                      justifyContent='space-between'
                      alignItems='center'
                    >
                      <Typography variant='h6'>{`Weight: ${data.weight} kg`}</Typography>

                      <IconButton
                        onClick={() => handleEditClick(data, index)}
                        style={{ color: index === 0 ? 'white' : grey[800] }}
                      >
                        <IconEdit />
                      </IconButton>
                    </Stack>

                    <Stack
                      direction='row'
                      justifyContent='space-between'
                      alignItems='center'
                    >
                      <Stack direction='row' alignItems='center' gap={0.5}>
                        <IconCalendar style={{ marginTop: -2 }} />
                        <Typography>
                          {format(new Date(data.weight_date), 'dd-MM-yyyy')}
                        </Typography>
                      </Stack>

                      <IconButton
                        onClick={() => handleDelete(index)}
                        color='error'
                      >
                        <IconTrash />
                      </IconButton>
                    </Stack>
                  </Stack>
                </Box>
              );
            })}
          </Stack>
        </Grid>

        <Stack
          direction='row'
          gap={1}
          alignItems='center'
          justifyContent='space-between'
          width='100%'
        >
          <Button
            variant='outlined'
            startIcon={<IconPlus />}
            onClick={handleAddWeightClick}
          >
            Add Weight
          </Button>

          <Stack direction='row' gap={1} alignItems='center'>
            <Button color='inherit' onClick={onClose}>
              Cancel
            </Button>
            <LoadingButton
              type='submit'
              variant='contained'
              disabled={!formik.isValid || !formik.dirty}
              loading={weightsMutation.isLoading}
            >
              Save
            </LoadingButton>
          </Stack>
        </Stack>

        {upsertWeightDialog.open && (
          <DialogSection
            title={
              upsertWeightDialog.data?.action === 'edit'
                ? 'Edit Weight'
                : 'Add Weight'
            }
            open={upsertWeightDialog.open}
            onClose={upsertWeightDialog.handleClose}
            maxWidth='xl'
          >
            <DialogContent>
              <Stack direction='column' spacing={1}>
                <NumberInput
                  label='Weight (kg)'
                  value={editData.weight}
                  isInt={false}
                  size='big'
                  style={{ width: '100%' }}
                  fullWidth
                  onChange={(value) =>
                    setEditData({ ...editData, weight: value })
                  }
                  onBlur={() =>
                    formik.setFieldTouched(
                      `weights[${editingIndex}].weight`,
                      true,
                    )
                  }
                />
                <LupaDatePicker
                  label='Weighting Date'
                  value={
                    editData.weight_date ? new Date(editData.weight_date) : null
                  }
                  onChange={(value) => {
                    if (value) {
                      setEditData({
                        ...editData,
                        weight_date: value.toISOString(),
                      });
                    }
                  }}
                  maxDate={new Date()}
                />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleAddOrEditWeight} variant='contained'>
                Save
              </Button>
            </DialogActions>
          </DialogSection>
        )}
      </Grid>
    </form>
  );
}
