import React, { useRef } from 'react';

// @ts-ignore
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
// @ts-ignore
import CryptoJS from 'crypto-js';

import { globalSingleton } from '~/singletons/globalSingleton';

const getHexEncodedHash = (message: string, secret: string) => {
  const hash = CryptoJS.HmacSHA256(message, secret);

  return CryptoJS.enc.Hex.stringify(hash);
};

export default function TawkMessenger() {
  const apiKey = '967c4f69281894fef284e8dee59c70c0d475a4b2';

  const tawkMessengerRef = useRef<any>();

  const onLoad = () => {
    setTimeout(() => {
      try {
        // @ts-ignore
        if (window.Tawk_API && tawkMessengerRef.current) {
          const { email } = globalSingleton.currentEmployee;
          const name = globalSingleton.currentEmployee.full_name;

          tawkMessengerRef.current.setAttributes(
            {
              name,
              store: globalSingleton.currentStore.name,
              storeId: globalSingleton.currentStore.id,
              employeeId: globalSingleton.currentEmployee.id,
              email,
              hash: getHexEncodedHash(email, apiKey),
            },
            (error: string) => {
              if (error) {
                console.error('Error setting Tawk attributes:', error);

                if (error === 'INVALID_EMAIL') {
                  // Tawk does not like some of the emails for some reason...
                  tawkMessengerRef.current.setAttributes({
                    name,
                    store: globalSingleton.currentStore.name,
                    storeId: globalSingleton.currentStore.id,
                    employeeId: globalSingleton.currentEmployee.id,
                    email: 'hello@lupapets.com',
                    hash: getHexEncodedHash('hello@lupapets.com', apiKey),
                  });
                }
              }
            },
          );
        } else {
          console.error('Tawk_API is not loaded yet.');
        }
      } catch (error) {
        console.error('Error loading Tawk', error);
      }
    }, 2000);
  };

  return (
    <TawkMessengerReact
      propertyId='65dcfb5c8d261e1b5f6591ba'
      widgetId='1hnjjbom5'
      onLoad={onLoad}
      ref={tawkMessengerRef}
    />
  );
}
