import React, { useState } from 'react';

import { Theme } from '@mui/material';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import {
  IconCalendar,
  IconChevronLeft,
  IconChevronRight,
} from '@tabler/icons-react';
import { format } from 'date-fns';
import { useAtom } from 'jotai';

import { TrpcRouterOutputs, trpc } from '~/lib/trpc';
import { employeeIdToColor } from '~/utils/calendar-utils';
import {
  CALENDAR_COLOUR_TYPE,
  CALENDAR_MODE_TYPE,
  CALENDAR_TIME_TYPE,
  CALENDAR_VIEW_TYPE,
} from '~/utils/local-enums';

import { mainCalendarColourType } from '../../atoms/atoms';
import {
  StyledToggleButton,
  StyledToggleButtonGroup,
} from '../StyledToggleButton';
import { AutocompleteSearch } from '../ui/AutocompleteSearch';

const getView = (
  viewModeType: CALENDAR_MODE_TYPE,
  viewTimeType: CALENDAR_TIME_TYPE,
) => {
  if (viewTimeType === CALENDAR_TIME_TYPE.WEEK) {
    return viewModeType === CALENDAR_MODE_TYPE.MERGED
      ? CALENDAR_VIEW_TYPE.TIME_GRID_WEEK
      : CALENDAR_VIEW_TYPE.RESOURCE_TIME_GRID_WEEK;
  }

  if (viewTimeType === CALENDAR_TIME_TYPE.DAY) {
    return viewModeType === CALENDAR_MODE_TYPE.MERGED
      ? CALENDAR_VIEW_TYPE.TIME_GRID_DAY
      : CALENDAR_VIEW_TYPE.RESOURCE_TIME_GRID_DAY;
  }

  if (viewTimeType === CALENDAR_TIME_TYPE.THREE_DAYS) {
    return viewModeType === CALENDAR_MODE_TYPE.MERGED
      ? CALENDAR_VIEW_TYPE.TIME_GRID_THREE_DAYS
      : CALENDAR_VIEW_TYPE.RESOURCE_TIME_GRID_THREE_DAYS;
  }

  return viewModeType === CALENDAR_MODE_TYPE.MERGED
    ? CALENDAR_VIEW_TYPE.RESOURCE_TIMELINE_WEEK
    : CALENDAR_VIEW_TYPE.RESOURCE_TIMELINE_WEEK;
};

type CalendarToolbarProps = {
  date: Date;
  isThreeDaysView: boolean;
  onDateNext: () => void;
  onDateToday: () => void;
  onDatePrev: () => void;
  onViewChange: (view: CALENDAR_VIEW_TYPE) => void;
  onDateChange: (date: Date) => void;
  selectedEmployees: any[];
  setSelectedEmployees: (employees: any[]) => void;
  view: CALENDAR_VIEW_TYPE;
  dateRange?: {
    startDate: string;
    endDate: string;
  };
};

export const CalendarToolbar = ({
  date,
  dateRange,
  isThreeDaysView,
  onDateNext,
  onDateToday,
  onDatePrev,
  onViewChange,
  onDateChange,
  selectedEmployees,
  setSelectedEmployees,
  view,
  ...other
}: CalendarToolbarProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const [colourType, setColourType] = useAtom(mainCalendarColourType);
  const [viewModeType, setViewModeType] = useState<CALENDAR_MODE_TYPE>(
    view === CALENDAR_VIEW_TYPE.TIME_GRID_WEEK ||
      view === CALENDAR_VIEW_TYPE.TIME_GRID_THREE_DAYS
      ? CALENDAR_MODE_TYPE.MERGED
      : CALENDAR_MODE_TYPE.SPLIT,
  );
  const [viewTimeType, setViewTimeType] = useState<CALENDAR_TIME_TYPE>(
    view.includes('Timeline')
      ? CALENDAR_TIME_TYPE.TIMELINE
      : view.includes('Week')
        ? CALENDAR_TIME_TYPE.WEEK
        : view.includes('Three')
          ? CALENDAR_TIME_TYPE.THREE_DAYS
          : CALENDAR_TIME_TYPE.DAY,
  );

  const dateDay = format(date, 'do');
  const endDateDate = dateRange?.endDate
    ? format(new Date(dateRange.endDate), 'do')
    : '';
  const dateMonth = format(date, 'MMM');
  const dateYear = format(date, 'yy');

  const open = Boolean(anchorEl);
  const id = open ? 'date-picker-popover' : undefined;

  return (
    <Stack direction='column' spacing={1} mb={2}>
      <Stack
        alignItems='center'
        flexWrap='wrap'
        justifyContent='space-between'
        flexDirection={{
          xs: 'column',
          md: 'row',
        }}
        px={2}
        spacing={3}
        {...other}
      >
        <Stack alignItems='center' direction='row' spacing={1}>
          <Typography
            sx={{ fontWeight: 400 }}
            variant='h5'
            data-testid='text-calendar-date'
          >{`${dateDay} ${endDateDate ? ` - ${endDateDate}` : ''}`}</Typography>
          <Typography variant='h5'>{dateMonth}</Typography>
          <Typography sx={{ fontWeight: 400 }} variant='h5'>
            {dateYear}
          </Typography>
        </Stack>

        <Stack
          alignItems='center'
          direction='row'
          spacing={0.5}
          style={{ marginTop: mdUp ? 0 : 8 }}
          data-testid='calendar-toolbar-buttons'
        >
          <IconButton
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
            data-testid='button-calendar-date-picker'
          >
            <IconCalendar />
          </IconButton>

          <IconButton onClick={onDatePrev} data-testid='button-calendar-prev'>
            <IconChevronLeft />
          </IconButton>

          <Button variant='contained' onClick={onDateToday}>
            Today
          </Button>

          <IconButton onClick={onDateNext} data-testid='button-calendar-next'>
            <IconChevronRight />
          </IconButton>
        </Stack>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <DateCalendar
            value={date}
            onChange={(value) => {
              onDateChange(value);
              setAnchorEl(null);
            }}
          />
        </Popover>
      </Stack>

      <Stack
        direction={{
          xs: 'column',
          md: 'row',
        }}
        alignItems='center'
        justifyContent='space-between'
        gap={2}
        px={3}
      >
        <Stack direction='row' spacing={2} alignItems='center'>
          <AutocompleteSearch<{
            ItemType: TrpcRouterOutputs['employees']['searchEmployees'][0];
            Multiple: true;
          }>
            trpcFunction={trpc.employees.searchEmployees}
            label='Employees'
            getOptionLabel={(option) => option.full_name}
            value={selectedEmployees ?? []}
            onSelect={setSelectedEmployees}
            multiple
            renderTags={(value, getTagProps) =>
              value.map((option, index: number) => (
                <Chip
                  {...getTagProps({ index })}
                  key={option.id}
                  label={option.full_name}
                  style={{ backgroundColor: employeeIdToColor(option.id) }}
                />
              ))
            }
            sx={{ minWidth: 300, flexGrow: mdUp ? 0 : 1 }}
          />
        </Stack>

        <Stack direction='row' spacing={2} alignItems='center'>
          <StyledToggleButtonGroup
            color='primary'
            value={viewModeType}
            exclusive
            onChange={(_, value) => {
              if (value == null) {
                return;
              }
              setViewModeType(value);
              onViewChange(getView(value, viewTimeType));
            }}
            disabled={viewTimeType === 'timeline'}
          >
            <StyledToggleButton value={CALENDAR_MODE_TYPE.MERGED} width={70}>
              Merged
            </StyledToggleButton>
            <StyledToggleButton value={CALENDAR_MODE_TYPE.SPLIT} width={70}>
              Split
            </StyledToggleButton>
          </StyledToggleButtonGroup>

          {!isThreeDaysView && (
            <StyledToggleButtonGroup
              color='primary'
              value={viewTimeType}
              exclusive
              onChange={(_, value) => {
                if (value == null) {
                  return;
                }

                if (value === 'timeline' && viewModeType === 'merged') {
                  setViewModeType(CALENDAR_MODE_TYPE.SPLIT);
                }

                if (value === 'timeline' && colourType === 'employee') {
                  setColourType(CALENDAR_COLOUR_TYPE.CATEGORY);
                }

                setViewTimeType(value);
                onViewChange(getView(viewModeType, value));
              }}
            >
              <StyledToggleButton value='day' width={70}>
                Day
              </StyledToggleButton>
              {isThreeDaysView && (
                <StyledToggleButton value='threeDays' width={70}>
                  3 Days
                </StyledToggleButton>
              )}
              <StyledToggleButton value='week' width={70}>
                Week
              </StyledToggleButton>
              <StyledToggleButton value='timeline' width={70}>
                Timeline
              </StyledToggleButton>
            </StyledToggleButtonGroup>
          )}

          {!isThreeDaysView && (
            <StyledToggleButtonGroup
              color='primary'
              value={colourType}
              exclusive
              onChange={(_, value) => {
                if (value == null) {
                  return;
                }

                setColourType(value);
              }}
            >
              <StyledToggleButton value='employee' width={90}>
                Employee
              </StyledToggleButton>
              <StyledToggleButton value='category' width={90}>
                Category
              </StyledToggleButton>
            </StyledToggleButtonGroup>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
