import React, { useMemo } from 'react';

import {
  FormControlLabel,
  FormHelperText,
  Stack,
  Switch,
  SxProps,
  Typography,
} from '@mui/material';
import { FormikProps, FormikValues } from 'formik';
import { Paths } from 'type-fest';

import { getFieldError, getFieldHelperText } from '~/utils/formik-utils';

interface FormSwitchFieldProps<Values> {
  name: Paths<Values>;
  label: string;
  formik: FormikProps<Values>;
  labelPlacement?: 'start' | 'top';
  afterInputChange?: (checked: boolean) => void;
  sx?: SxProps;
}

export default function FormSwitchField<Values extends FormikValues>({
  name,
  label,
  formik,
  labelPlacement = 'start',
  afterInputChange,
  sx,
}: FormSwitchFieldProps<Values>) {
  const fieldNameStr = name.toString();

  return useMemo(
    () => (
      <Stack direction='column' sx={sx}>
        <FormControlLabel
          name={fieldNameStr}
          control={
            <Switch
              checked={formik.values[fieldNameStr] ?? false}
              onChange={() => {
                formik
                  .setFieldValue(fieldNameStr, !formik.values[fieldNameStr])
                  .then(() => {
                    afterInputChange?.(!formik.values[fieldNameStr]);
                  });
              }}
              name={fieldNameStr}
              style={{ pointerEvents: 'auto' }}
            />
          }
          label={<Typography fontSize={14}>{label}</Typography>}
          labelPlacement={labelPlacement}
          style={{
            pointerEvents: 'none',
            width: 'fit-content',
            marginLeft: 0,
          }}
        />

        {getFieldError(formik, fieldNameStr) && (
          <FormHelperText
            error
            sx={{
              ml: 1,
            }}
          >
            {getFieldHelperText(formik, fieldNameStr)}
          </FormHelperText>
        )}
      </Stack>
    ),
    [
      formik.values[fieldNameStr],
      fieldNameStr,
      formik.errors[fieldNameStr],
      formik.touched[fieldNameStr],
    ],
  );
}
