import React from 'react';

import { Button, Stack, Typography } from '@mui/material';

export default function ErrorBoundaryFallback() {
  return (
    <Stack
      direction='column'
      alignItems='center'
      justifyContent='center'
      flexGrow={1}
      mt={8}
    >
      <img
        src='/assets/logo.png'
        alt='logo'
        style={{ width: 80, height: 80, borderRadius: 16 }}
      />
      <Typography variant='h6'>Something went wrong :(</Typography>
      <Button
        variant='contained'
        color='primary'
        onClick={() => {
          window.location.href = '/';
        }}
        sx={{ mt: 2 }}
      >
        Return home
      </Button>
    </Stack>
  );
}
