import React, { useEffect, useRef, useState } from 'react';

import {
  Avatar,
  ButtonBase,
  Card,
  IconButton,
  Popover,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconSend, IconX } from '@tabler/icons-react';
import toast from 'react-hot-toast';

import { trcpProxyClient } from '~/lib/trpc';

import LoadingIconButton from './ui/LoadingIconButton';

const TODO_JERRY_AI = false;

export default function JerryChat() {
  const scrollableDivRef = useRef<HTMLDivElement>(null);
  const theme: any = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState<
    {
      content: string;
      role: 'assistant' | 'user';
    }[]
  >([
    {
      content: 'Hey! My name is Jerry, I am your personal AI assistant! 🚀',
      role: 'assistant',
    },
  ]);
  const [inputValue, setInputValue] = useState('');
  const open = Boolean(anchorEl);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (scrollableDivRef.current) {
        scrollableDivRef.current.scrollTop =
          scrollableDivRef.current.scrollHeight;
      }
    }, 0);

    return () => clearTimeout(timer);
  }, [open, messages]);

  if (!TODO_JERRY_AI) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSendMessage = async () => {
    setLoading(true);
    const newMessages = [
      ...messages,
      { content: inputValue, role: 'user' as const },
    ];
    setMessages(newMessages);
    setInputValue('');

    const data = await trcpProxyClient.store.messageJerry.mutate(
      newMessages.slice(1),
    );

    if (data == null) {
      toast.error('Something went wrong :( - Try again later!');
      setLoading(false);
      return;
    }

    setMessages([...newMessages, { content: data, role: 'assistant' }]);

    setLoading(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === 'Enter' && event.shiftKey === false) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div>
      <ButtonBase
        id='jerry-chat'
        onClick={handleClick}
        style={{
          position: 'fixed',
          bottom: 20,
          left: 20,
          width: 60,
          height: 60,
          borderRadius: '100%',
          zIndex: 10000,
        }}
      >
        <img
          alt='jerry-chat'
          width={60}
          height={60}
          src='/assets/morphing-fluid.gif'
          style={{
            borderRadius: '100%',
            position: 'absolute',
            border: '3px solid',
            borderColor: theme.palette.primary.main,
          }}
        />

        <img
          alt='jerry'
          width={50}
          height={50}
          src='/assets/jerry.png'
          style={{ borderRadius: '100%', position: 'absolute', bottom: 2 }}
        />
      </ButtonBase>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ marginTop: -80 }}
        slotProps={{
          paper: { style: { borderRadius: 16 } },
        }}
      >
        <Card style={{ borderRadius: 16 }}>
          <Stack justifyContent='space-between' height={600} width={300}>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              p={2}
              style={{
                backgroundColor: theme.palette.primary.main,
              }}
            >
              <Stack direction='row' alignItems='center'>
                <Avatar src='/assets/jerry.png' />

                <Stack direction='column'>
                  <Typography
                    variant='h6'
                    color='white'
                    style={{ marginLeft: 8 }}
                  >
                    Jerry
                  </Typography>
                  <Typography
                    variant='body2'
                    color='white'
                    style={{ marginLeft: 8 }}
                  >
                    AI Assistant
                  </Typography>
                </Stack>
              </Stack>

              <IconButton onClick={handleClose}>
                <IconX color='white' />
              </IconButton>
            </Stack>

            <Stack>
              <div
                ref={scrollableDivRef}
                style={{
                  maxHeight: 470,
                  overflowY: 'scroll',
                  paddingTop: 8,
                }}
              >
                <Stack flexGrow={1} px={2} spacing={1}>
                  {messages.map((message, index) => {
                    const isAssistant = message.role === 'assistant';
                    return (
                      <Stack
                        key={index}
                        alignSelf={isAssistant ? 'flex-start' : 'flex-end'}
                        paddingRight={isAssistant ? 3 : 0}
                        paddingLeft={isAssistant ? 0 : 3}
                      >
                        <div
                          style={{
                            backgroundColor: isAssistant
                              ? theme.palette.primary.light
                              : theme.palette.neutral[200],
                            padding: 8,
                            borderRadius: 16,
                            alignSelf: isAssistant ? 'flex-start' : 'flex-end',
                            alignItems: 'flex-start',
                          }}
                        >
                          <Typography>{message.content}</Typography>
                        </div>
                      </Stack>
                    );
                  })}
                </Stack>
              </div>

              <Stack direction='row' alignItems='center' p={1}>
                <TextField
                  fullWidth
                  variant='outlined'
                  placeholder='Ask anything'
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyDown={handleInputKeyPress}
                  size='small'
                  style={{ margin: '0 8px 0 0' }}
                  multiline
                />
                <LoadingIconButton
                  color='primary'
                  size='small'
                  onClick={handleSendMessage}
                  disabled={inputValue.trim() === ''}
                  loading={loading}
                  loadingStyle={{ marginLeft: 14 }}
                >
                  <IconSend />
                </LoadingIconButton>
              </Stack>
            </Stack>
          </Stack>
        </Card>
      </Popover>
    </div>
  );
}
