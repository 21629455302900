import Intercom from '@intercom/messenger-js-sdk';

import { globalSingleton } from '~/singletons/globalSingleton';

export default function IntercomComponent() {
  if (!globalSingleton.isTestUser) {
    return null;
  }

  Intercom({
    app_id: 'g3qb8kec',
    user_id: globalSingleton.userId ?? undefined,
    name: globalSingleton.currentEmployee.full_name,
    email: globalSingleton.currentEmployee.email,
    phone: globalSingleton.currentEmployee.phone ?? undefined,
    user_hash: globalSingleton.currentEmployee.intercomHash ?? undefined,
    company: {
      company_id: globalSingleton.currentStore.company.id,
      name: globalSingleton.currentStore.company.name,
    },
  });

  return null;
}
