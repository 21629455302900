import React, { ReactNode, useMemo } from 'react';

import { Theme, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  IconApps,
  IconCalendar,
  IconListCheck,
  IconMessageCircle2,
  IconPaw,
  IconSettings,
} from '@tabler/icons-react';
import { useAtom } from 'jotai';

import TawkMessenger from '~/components/TawkMessenger';
import { PERMISSION_CATEGORY } from '~/utils/enums';
import { IS_DEV } from '~/utils/networkUtils';

import { mainDrawerOpenAtom } from '../atoms/atoms';
import { useMobileNav } from '../hooks/use-mobile-nav';
import { paths } from '../paths';
import { getIsFullStore, hasEmployeePermissions } from '../utils/store-utils';
import { MobileNav } from './MobileNav';
import { SideNav } from './SideNav';
import { TopNav } from './TopNav';

const TOP_NAV_HEIGHT = 64;
const SIDE_NAV_WIDTH = 280;

type VerticalLayoutRootProps = {
  theme?: any;
  mainDrawerOpen: boolean;
};

const VerticalLayoutRoot = styled('div')<VerticalLayoutRootProps>(
  ({ theme, mainDrawerOpen }) => ({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    backgroundColor: theme.palette.neutral[800],
    [theme.breakpoints.up('lg')]: {
      paddingLeft: mainDrawerOpen ? SIDE_NAV_WIDTH : 0,
    },
  }),
);

type VerticalLayoutContainerProps = {
  theme?: any;
  mainDrawerOpen: boolean;
  lgUp: boolean;
};

const VerticalLayoutContainer = styled('div')<VerticalLayoutContainerProps>(
  ({ theme, mainDrawerOpen, lgUp }) => ({
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    width: '100%',
    borderTopLeftRadius: lgUp && mainDrawerOpen ? 32 : 0,
    backgroundColor: theme.palette.neutral[50],
    maxHeight: `calc(100vh - ${TOP_NAV_HEIGHT}px)`,
    overflowY: 'auto',
  }),
);

type VerticalLayoutProps = {
  children: ReactNode;
};

export const VerticalLayout: React.FC<VerticalLayoutProps> = ({ children }) => {
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const [mainDrawerOpen] = useAtom(mainDrawerOpenAtom);
  const mobileNav = useMobileNav();
  const isFullStore = getIsFullStore();

  const sections = useMemo(
    () => [
      {
        items: [
          {
            title: 'Calendar',
            path: paths.index,
            icon: <IconCalendar />,
            testId: 'top-nav-calendar',
          },
          {
            title: 'Appointments',
            path: paths.appointments.index,
            icon: <IconListCheck />,
            testId: 'top-nav-appointments',
          },
          {
            title: 'Pets',
            path: paths.clients.pets,
            icon: <IconPaw />,
            testId: 'top-nav-clients',
          },
          {
            title: 'Chat',
            path: paths.chat,
            icon: <IconMessageCircle2 />,
            testId: 'top-nav-chat',
            permission_category: PERMISSION_CATEGORY.CHAT,
          },
          isFullStore && {
            title: 'Apps',
            path: paths.apps.index,
            icon: <IconApps />,
            testId: 'top-nav-apps',
          },
          {
            title: 'Settings',
            path: paths.settings.index,
            testId: 'top-nav-settings',
            icon: <IconSettings />,
          },
        ]
          .filter(Boolean)
          .filter((item: any) => {
            if (item.permission_category != null) {
              return hasEmployeePermissions(item.permission_category);
            }

            return true;
          }),
      },
    ],
    [isFullStore],
  );

  return (
    <>
      <TopNav sections={sections} onMobileNavOpen={mobileNav.handleOpen} />

      {lgUp && <SideNav />}

      {!lgUp && (
        <MobileNav
          onClose={mobileNav.handleClose}
          open={mobileNav.open}
          sections={sections}
        />
      )}

      <VerticalLayoutRoot mainDrawerOpen={mainDrawerOpen}>
        <VerticalLayoutContainer mainDrawerOpen={mainDrawerOpen} lgUp={lgUp}>
          {children}
        </VerticalLayoutContainer>
      </VerticalLayoutRoot>

      {!IS_DEV && <TawkMessenger />}
    </>
  );
};
