import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Chip, Link, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { deepPurple } from '@mui/material/colors';
import {
  IconArrowRight,
  IconDownload,
  IconPaw,
  IconSearch,
  IconX,
} from '@tabler/icons-react';
import { differenceInMinutes, format } from 'date-fns';
import PropTypes from 'prop-types';
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';

import { RouterLink } from '~/components/RouterLink';
import FallbackAvatar from '~/components/ui/FallbackAvatar';
import LoadingIconButton from '~/components/ui/LoadingIconButton';
import { trpc } from '~/lib/trpc';
import { paths } from '~/paths';
import { globalSingleton } from '~/singletons/globalSingleton';
import { capitalize } from '~/utils/capitalize';
import { getImagePublicUrl } from '~/utils/get-image-public-url';
import { getInitials } from '~/utils/get-initials';
import { getEmployeeRoleLabel } from '~/utils/get-labels';

import { Tip } from '../components/ui/Tip';

interface SearchDialogProps extends DialogProps {
  onClose: () => void;
  open: boolean;
}

export const SearchDialog = ({
  onClose,
  open = false,
  ...other
}: SearchDialogProps) => {
  const [value, setValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [displayArticles, setDisplayArticles] = useState(false);
  const [results, setResults] = useState<typeof searchMutation.data>(undefined);
  const location = useLocation();
  const navigate = useNavigate();
  const firstRender = useRef(true);

  const searchMutation = trpc.store.getGeneralSearch.useMutation();

  const importClientToStoreMutation =
    trpc.store.importClientToStore.useMutation({
      onSuccess: (response) => {
        toast.success('Client imported to store');
        navigate(paths.clients.details(response.client_id));
      },
      onError: (error) => {
        console.error(error);
        toast.error('Something went wrong.');
      },
    });

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      onClose();
    }
  }, [location]);

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      setDisplayArticles(false);
      setIsLoading(true);
      // Do search here
      const searchResults = await searchMutation.mutateAsync(value);
      setResults(searchResults);

      setIsLoading(false);
      setDisplayArticles(true);
    },
    [value],
  );

  return (
    <Dialog fullWidth maxWidth='md' onClose={onClose} open={open} {...other}>
      <Stack
        alignItems='center'
        direction='row'
        justifyContent='space-between'
        spacing={3}
        sx={{
          px: 3,
          pt: 2,
        }}
      >
        <Typography variant='h6'>Search</Typography>
        <IconButton color='inherit' onClick={onClose}>
          <IconX />
        </IconButton>
      </Stack>
      <DialogContent>
        <Tip message='Search appointments, clients or pets by entering a keyword and pressing Enter' />
        <Box component='form' onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <IconSearch />
                </InputAdornment>
              ),
            }}
            autoFocus
            autoComplete='off'
            label='Search'
            onChange={(event) => setValue(event.target.value)}
            placeholder='Search...'
            value={value}
          />
        </Box>

        {isLoading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 3,
            }}
          >
            <CircularProgress />
          </Box>
        )}

        {displayArticles && results && (
          <Stack spacing={2} sx={{ mt: 3 }}>
            {Object.keys(results).map((type, index) => {
              if (!results[type as keyof typeof results].length) {
                return null;
              }

              return (
                <Stack key={index} spacing={2}>
                  <Typography variant='h6'>{capitalize(type)}</Typography>
                  <Stack
                    divider={<Divider />}
                    sx={{
                      borderColor: 'divider',
                      borderRadius: 1,
                      borderStyle: 'solid',
                      borderWidth: 1,
                    }}
                  >
                    {results[type as keyof typeof results].map((result) => {
                      if (type === 'clients') {
                        const client = result as (typeof results)['clients'][0];
                        const appointment = client.appointments?.[0];
                        const isClientInCurrentStore = client.stores.some(
                          (store) =>
                            store.id === globalSingleton.currentStore.id,
                        );

                        return (
                          <Box key={client.id} sx={{ p: 2 }}>
                            <Grid container alignItems='center' spacing={2}>
                              <Grid xs={3}>
                                <Stack
                                  alignItems='center'
                                  direction='row'
                                  spacing={1}
                                >
                                  <FallbackAvatar
                                    src={getImagePublicUrl(client.avatar_url)}
                                    size={42}
                                  >
                                    {getInitials(client.full_name)}
                                  </FallbackAvatar>
                                  <div>
                                    <Stack>
                                      <Link
                                        color='inherit'
                                        component={RouterLink}
                                        href={paths.clients.details(client.id)}
                                        variant='subtitle2'
                                      >
                                        {client.full_name}
                                      </Link>
                                      <Stack direction='row'>
                                        <Chip
                                          label={
                                            client.profile_id != null
                                              ? 'Mobile User'
                                              : 'Not Mobile User'
                                          }
                                          color={
                                            client.profile_id != null
                                              ? 'primary'
                                              : 'default'
                                          }
                                          size='small'
                                        />
                                      </Stack>
                                    </Stack>
                                  </div>
                                </Stack>
                              </Grid>

                              <Grid xs={4}>
                                <Typography variant='subtitle2'>
                                  {client.phone}
                                </Typography>
                                <Typography variant='subtitle2'>
                                  {client.email}
                                </Typography>
                              </Grid>

                              <Grid xs={4}>
                                {isClientInCurrentStore && appointment && (
                                  <Stack direction='column' gap={1}>
                                    <Link
                                      color='inherit'
                                      component={RouterLink}
                                      href={paths.appointments.details(
                                        appointment.id,
                                      )}
                                      variant='subtitle2'
                                    >
                                      {appointment.title}
                                    </Link>

                                    <Stack direction='column'>
                                      <Typography variant='subtitle2'>
                                        {format(
                                          new Date(appointment.start),
                                          'dd/MM/yy HH:mm',
                                        )}
                                      </Typography>
                                      <Typography
                                        color='text.secondary'
                                        variant='body2'
                                      >
                                        {`${differenceInMinutes(
                                          new Date(appointment.end),
                                          new Date(appointment.start),
                                        )} mins`}
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                )}

                                {!isClientInCurrentStore && (
                                  <Typography variant='subtitle2'>
                                    Not in current store
                                  </Typography>
                                )}
                              </Grid>

                              <Grid xs={1}>
                                {isClientInCurrentStore ? (
                                  <IconButton
                                    component={RouterLink}
                                    href={paths.clients.details(client.id)}
                                  >
                                    <IconArrowRight size={20} />
                                  </IconButton>
                                ) : (
                                  <Tooltip
                                    title='Add to current store'
                                    placement='top'
                                  >
                                    <span>
                                      <LoadingIconButton
                                        onClick={() => {
                                          importClientToStoreMutation.mutate({
                                            clientId: client.id,
                                          });
                                        }}
                                        loading={
                                          importClientToStoreMutation.isLoading
                                        }
                                        size='small'
                                      >
                                        <IconDownload size={20} />
                                      </LoadingIconButton>
                                    </span>
                                  </Tooltip>
                                )}
                              </Grid>
                            </Grid>
                          </Box>
                        );
                      }

                      if (type === 'pets') {
                        const pet = result as (typeof results)['pets'][0];
                        const appointment = pet.appointments?.[0];

                        return (
                          <Box key={pet.id} sx={{ p: 2 }}>
                            <Grid container alignItems='center' spacing={2}>
                              <Grid xs={3}>
                                <Stack
                                  alignItems='center'
                                  direction='row'
                                  spacing={1}
                                >
                                  <FallbackAvatar
                                    src={getImagePublicUrl(pet.avatar_url)}
                                    fallbackIcon={
                                      <IconPaw color={deepPurple.A400} />
                                    }
                                    size={42}
                                  >
                                    {getInitials(pet.name)}
                                  </FallbackAvatar>
                                  <div>
                                    <Link
                                      color='inherit'
                                      component={RouterLink}
                                      href={paths.pets.details(pet.id)}
                                      variant='subtitle2'
                                    >
                                      {pet.name}
                                    </Link>
                                    <Typography
                                      color='text.secondary'
                                      variant='body2'
                                    >
                                      {pet.species}
                                    </Typography>
                                  </div>
                                </Stack>
                              </Grid>

                              <Grid xs={4}>
                                <Typography variant='subtitle2'>
                                  {pet.owner_full_name}
                                </Typography>
                              </Grid>

                              <Grid xs={4}>
                                {appointment && (
                                  <Stack direction='column' gap={1}>
                                    <Link
                                      color='inherit'
                                      component={RouterLink}
                                      href={paths.appointments.details(
                                        appointment.id,
                                      )}
                                      variant='subtitle2'
                                    >
                                      {appointment.title}
                                    </Link>

                                    <Stack direction='column'>
                                      <Typography variant='subtitle2'>
                                        {format(
                                          new Date(appointment.start),
                                          'dd/MM/yy HH:mm',
                                        )}
                                      </Typography>
                                      <Typography
                                        color='text.secondary'
                                        variant='body2'
                                      >
                                        {`${differenceInMinutes(
                                          new Date(appointment.end),
                                          new Date(appointment.start),
                                        )} mins`}
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                )}
                              </Grid>

                              <Grid xs={1}>
                                <IconButton
                                  component={RouterLink}
                                  href={paths.pets.details(pet.id)}
                                >
                                  <IconArrowRight size={20} />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Box>
                        );
                      }

                      if (type === 'appointments') {
                        const appointment =
                          result as (typeof results)['appointments'][0];

                        return (
                          <Box key={appointment.id} sx={{ p: 2 }}>
                            <Grid container alignItems='center' spacing={2}>
                              <Grid xs={4}>
                                <Stack alignItems='center' direction='row'>
                                  <Stack direction='column' gap={1}>
                                    <Link
                                      color='inherit'
                                      component={RouterLink}
                                      href={paths.appointments.details(
                                        appointment.id,
                                      )}
                                      variant='subtitle2'
                                    >
                                      {appointment.title}
                                    </Link>

                                    <Stack direction='column'>
                                      <Typography variant='subtitle2'>
                                        {format(
                                          new Date(appointment.start),
                                          'dd/MM/yy HH:mm',
                                        )}
                                      </Typography>
                                      <Typography
                                        color='text.secondary'
                                        variant='body2'
                                      >
                                        {`${differenceInMinutes(
                                          new Date(appointment.end),
                                          new Date(appointment.start),
                                        )} mins`}
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                </Stack>
                              </Grid>

                              <Grid xs={3}>
                                <Stack
                                  key={appointment.pet.id}
                                  alignItems='center'
                                  direction='row'
                                  spacing={1}
                                >
                                  <FallbackAvatar
                                    src={getImagePublicUrl(
                                      appointment.pet.avatar_url,
                                    )}
                                    fallbackIcon={
                                      <IconPaw color={deepPurple.A400} />
                                    }
                                    size={42}
                                  />

                                  <Stack direction='column' maxWidth={120}>
                                    <Link
                                      color='inherit'
                                      component={RouterLink}
                                      href={paths.pets.details(
                                        appointment.pet.id,
                                      )}
                                      variant='subtitle2'
                                    >
                                      {appointment.pet.name}
                                    </Link>
                                    <Typography
                                      color='text.secondary'
                                      variant='subtitle2'
                                    >
                                      {appointment.pet.species}
                                    </Typography>
                                    <Link
                                      color='inherit'
                                      component={RouterLink}
                                      href={paths.clients.details(
                                        appointment.client.id,
                                      )}
                                      variant='subtitle2'
                                      sx={{
                                        display: 'inline-block',
                                        maxWidth: '100%',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                      }}
                                    >
                                      {appointment.client.full_name}
                                    </Link>
                                  </Stack>
                                </Stack>
                              </Grid>

                              <Grid xs={4}>
                                <Stack direction='column' spacing={1}>
                                  {appointment.appointments_employees.map(
                                    ({ employee }) => (
                                      <Stack
                                        key={employee.id}
                                        alignItems='center'
                                        direction='row'
                                        spacing={1}
                                      >
                                        <FallbackAvatar
                                          src={getImagePublicUrl(
                                            employee.avatar_url,
                                          )}
                                          size={42}
                                        />
                                        <div>
                                          <Link
                                            color='inherit'
                                            component={RouterLink}
                                            href={paths.employees.details(
                                              employee.id,
                                            )}
                                            variant='subtitle2'
                                          >
                                            {employee.full_name}
                                          </Link>
                                          <Typography
                                            color='text.secondary'
                                            variant='body2'
                                          >
                                            {getEmployeeRoleLabel(
                                              employee.role,
                                            )}
                                          </Typography>
                                        </div>
                                      </Stack>
                                    ),
                                  )}
                                </Stack>
                              </Grid>

                              <Grid xs={1}>
                                <IconButton
                                  component={RouterLink}
                                  href={paths.appointments.details(
                                    appointment.id,
                                  )}
                                >
                                  <IconArrowRight size={20} />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Box>
                        );
                      }

                      return null;
                    })}
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
};

SearchDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
