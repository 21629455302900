import React, { useEffect } from 'react';

import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { IconButton, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import { FormikProps } from 'formik';
import { FormikNumberInput } from 'lupa-shared-ui';
import { toast } from 'react-hot-toast';
import { P, match } from 'ts-pattern';

import { useRouter } from '~/hooks/use-router';
import { trpc } from '~/lib/trpc';
import { getCurrencySymbol } from '~/utils/i18n';
import mathUtils from '~/utils/math-utils';
import productUtils from '~/utils/product-utils';
import { throwIfNullish } from '~/utils/type-utils';

import { paths } from '../../paths';
import {
  PRODUCT_CATEGORY_TYPE,
  PRODUCT_MEDICAL_CATEGORY_TYPE,
} from '../../utils/enums';
import {
  getProductCategoryLabel,
  getProductMedicalCategoryLabel,
} from '../../utils/get-labels';
import { RouterLink } from '../RouterLink';
import CardSection from '../ui/CardSection';
import FormDateField from '../ui/form/FormDateField';
import FormLocalAutocompleteField from '../ui/form/FormLocalAutocompleteField';
import FormRemoteAutocompleteField from '../ui/form/FormRemoteAutocompleteField';
import FormSelectField from '../ui/form/FormSelectField';
import FormSwitchField from '../ui/form/FormSwitchField';
import FormTextField from '../ui/form/FormTextField';
import { ProductCreateForm } from './InventoryProductCreate';
import InventoryProductFormDiscountsChart from './InventoryProductFormDiscountsChart';
import { ProductUpdateForm } from './InventoryProductUpdate';

const getIsMedicalCategory = (category: PRODUCT_CATEGORY_TYPE) => {
  return (
    category === PRODUCT_CATEGORY_TYPE.GENERAL_MEDICATION ||
    category === PRODUCT_CATEGORY_TYPE.VACCINATION ||
    category === PRODUCT_CATEGORY_TYPE.ANTI_PARASITIC
  );
};

const vatOptions = [
  { value: 'standard', label: 'Standard (20%)', number: 20 },
  { value: 'reduced', label: 'Reduced (5%)', number: 5 },
  { value: 'exempt', label: 'Exempt (0%)', number: 0 },
  { value: 'zero', label: 'Zero (0%)', number: 0 },
  { value: 'custom', label: 'Custom', number: 0 },
];

const getUnitsAndSubunits = (
  category: PRODUCT_CATEGORY_TYPE | null,
): { units: string[]; subunits: string[] } => {
  switch (category) {
    case PRODUCT_CATEGORY_TYPE.ANTI_PARASITIC:
      return {
        units: ['Bottle', 'Box', 'Tube', 'Packet'],
        subunits: ['Tablets', 'Capsules', 'Milliliters', 'Grams'],
      };

    case PRODUCT_CATEGORY_TYPE.CONSUMABLES:
      return {
        units: ['Pack', 'Box', 'Bag'],
        subunits: ['Grams', 'Pieces', 'Milliliters'],
      };

    case PRODUCT_CATEGORY_TYPE.GENERAL_MEDICATION:
      return {
        units: ['Bottle', 'Box', 'Vial', 'Syringe'],
        subunits: ['Tablets', 'Capsules', 'Milliliters', 'Doses'],
      };

    case PRODUCT_CATEGORY_TYPE.GROOMING_CARE:
      return {
        units: ['Bottle', 'Tube', 'Can', 'Pack'],
        subunits: ['Milliliters', 'Grams'],
      };

    case PRODUCT_CATEGORY_TYPE.NUTRITION:
      return {
        units: ['Bag', 'Can', 'Tub', 'Bottle'],
        subunits: ['Grams', 'Kilograms', 'Milliliters', 'Servings'],
      };

    case PRODUCT_CATEGORY_TYPE.PET_ACCESSORIES:
      return {
        units: ['Piece', 'Pack', 'Box'],
        subunits: ['Items', 'Centimeters', 'Inches'],
      };

    case PRODUCT_CATEGORY_TYPE.VACCINATION:
      return {
        units: ['Vial', 'Syringe', 'Box'],
        subunits: ['Doses', 'Milliliters'],
      };

    case PRODUCT_CATEGORY_TYPE.OTHER:
    default:
      return {
        units: ['Piece', 'Pack', 'Box', 'Bottle'],
        subunits: ['Items', 'Milliliters', 'Grams'],
      };
  }
};

interface InventoryUpsertProductFormProps {
  formik: FormikProps<ProductUpdateForm> | FormikProps<ProductCreateForm>;
  action: 'create' | 'update';
  productId?: string;
}

export default function InventoryUpsertProductForm({
  formik,
  action,
  productId,
}: InventoryUpsertProductFormProps) {
  const router = useRouter();

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const formattedFormik = formik as FormikProps<
    ProductUpdateForm | ProductCreateForm
  >;

  // Updates price when other fields change (except margin)
  useEffect(() => {
    const price = productUtils.calculatePrice(formik, formik.values.margin);

    formik.setFieldValue('price', mathUtils.roundCurrency(price));
  }, [
    formik.values.procurement_cost,
    formik.values.vat_percentage,
    formik.values.discount_1,
    formik.values.discount_2,
    formik.values.discount_3,
  ]);

  const deleteProductMutation = trpc.products.deleteProduct.useMutation({
    onSuccess: () => {
      toast.success('Product deleted');
      router.replace(paths.inventory.index);
    },
    onError: (error) => {
      console.error(error);
      toast.error('Something went wrong!');
    },
  });

  return (
    <Stack direction='column' gap={2}>
      <CardSection title='Supplier'>
        <Stack
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          spacing={2}
        >
          <FormTextField
            name='supplier'
            label='Supplier Name (Optional)'
            formik={formattedFormik}
            sx={{ flex: '1 1 0' }}
          />

          <FormRemoteAutocompleteField
            trpcProcedure={trpc.products.searchSupplierProducts}
            extraTrpcParams={{
              supplierId: null, // TODO: Pass supplier ID here
            }}
            name='supplier_product'
            value={formik.values.supplier_product}
            label='Supplier Product (Optional)'
            formik={formik}
            getOptionLabel={(option) => {
              return match(option)
                .with(P.nullish, () => '')
                .otherwise(({ description }) => description);
            }}
            getOptionKey={(option) => option.id}
            afterInputChange={(value) => {
              if (value) {
                formik.setFieldValue('name', value.description);
                formik.setFieldValue('item_code', value.code);
                formik.setFieldValue('procurement_cost', value.cost);
              }
            }}
            sx={{ flex: '1 1 0' }}
          />
        </Stack>
      </CardSection>

      <CardSection title='Product Description'>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
          <Stack direction='column' spacing={2} flex='1 1 0'>
            <FormSelectField
              name='category'
              label='Category'
              formik={formattedFormik}
              options={Object.values(PRODUCT_CATEGORY_TYPE)}
              getOptionValue={(option) => option}
              getOptionLabel={(option) => getProductCategoryLabel(option)}
              afterInputChange={() => {
                formik.setFieldValue('medical_category', null);
              }}
            />

            {getIsMedicalCategory(formik.values.category) && (
              <FormSelectField
                name='medical_category'
                label='Medical Category'
                formik={formattedFormik}
                options={Object.values(PRODUCT_MEDICAL_CATEGORY_TYPE)}
                getOptionValue={(option) => option}
                getOptionLabel={(option) =>
                  getProductMedicalCategoryLabel(option)
                }
              />
            )}

            <FormTextField
              name='name'
              label='Product Name'
              formik={formattedFormik}
            />

            <FormTextField
              name='item_code'
              label='Item Code (optional)'
              formik={formattedFormik}
            />
          </Stack>

          <FormTextField
            name='description'
            label='Product Description (optional)'
            formik={formattedFormik}
            multiline
            minRows={7}
            sx={{ flex: '1 1 0' }}
          />
        </Stack>
      </CardSection>

      <CardSection title='Count'>
        <Stack direction='row' alignItems='center' gap={3} mt={1} mb={1}>
          <Typography variant='h6'>Measurements</Typography>

          <FormSwitchField
            name='has_subunit'
            label='Has Subunits?'
            formik={formattedFormik}
            afterInputChange={(checked) => {
              if (checked) {
                formik.setFieldValue('subunit_multiplier', 1);
              } else {
                formik.setFieldValue('subunit_multiplier', 0);
                formik.setFieldValue('unit', null);
                formik.setFieldValue('subunit', null);
              }
            }}
          />
        </Stack>

        {formik.values.has_subunit && (
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            alignItems='flex-start'
            spacing={2}
          >
            <FormLocalAutocompleteField
              name='unit'
              label='Unit (Select or type)'
              formik={formattedFormik}
              options={getUnitsAndSubunits(formik.values.category).units}
              getOptionLabel={(option) => option}
              getOptionKey={(option) => option}
              freeSolo
              sx={{ width: 180, pt: 0.5 }}
            />

            <Stack alignItems='center'>
              <Typography variant='caption' color='text.secondary'>
                Contains
              </Typography>
              <FormikNumberInput
                name='subunit_multiplier'
                label=''
                formik={formattedFormik}
                value={formik.values.subunit_multiplier}
                size='small'
                showArrows
                sx={{ width: 120 }}
              />
            </Stack>

            <Stack direction='column' alignItems='center'>
              <FormLocalAutocompleteField
                name='subunit'
                label='Subunit (Select or type)'
                formik={formattedFormik}
                options={getUnitsAndSubunits(formik.values.category).subunits}
                getOptionLabel={(option) => option}
                getOptionKey={(option) => option}
                freeSolo
                sx={{ width: 180 }}
              />

              {formik.values.unit && formik.values.subunit && (
                <Typography variant='caption' color='text.secondary'>
                  {`1 ${formik.values.unit} contains ${formik.values.subunit_multiplier} ${formik.values.subunit}`}
                </Typography>
              )}
            </Stack>
          </Stack>
        )}

        <Stack direction='row' alignItems='center' gap={3} mt={1} mb={1}>
          <Typography variant='h6'>Stock</Typography>

          <FormSwitchField
            name='has_batches'
            label='Enable Batches'
            formik={formattedFormik}
            afterInputChange={(checked) => {
              if (!checked) {
                formik.setFieldValue('batches', []);
              } else {
                formik.setFieldValue('batches', [
                  {
                    batch_number: '',
                    expiry_date: null,
                    quantity: 1,
                    measure_unit: formik.values.unit,
                  },
                ]);
              }
            }}
          />
        </Stack>

        {!formik.values.has_batches && (
          <Stack direction='row' alignItems='center' gap={1}>
            <FormikNumberInput
              name='quantity'
              label='Stock'
              formik={formattedFormik}
              value={formik.values.quantity}
              isInt={false}
              showArrows
              sx={{ width: 140 }}
            />

            {formik.values.has_subunit && (
              <FormSelectField
                name='measure_unit'
                label='Measure Unit'
                formik={formattedFormik}
                options={[
                  formik.values.unit ?? '',
                  formik.values.subunit ?? '',
                ]}
                getOptionValue={(option) => option}
                getOptionLabel={(option) => option}
                sx={{ width: 160 }}
              />
            )}
          </Stack>
        )}

        {formik.values.has_batches && (
          <Stack direction='column' spacing={1}>
            {formik.values.batches?.map((stock, idx) => (
              <Stack
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
                alignItems='center'
                direction={{ xs: 'column', md: 'row' }}
                spacing={2}
              >
                <FormikNumberInput
                  name={`batches.${idx}.quantity`}
                  label='Quantity'
                  formik={formattedFormik}
                  value={stock.quantity}
                  isInt={false}
                  showArrows
                  sx={{ width: 140 }}
                />

                {formik.values.has_subunit && (
                  <FormSelectField
                    name='measure_unit'
                    label='Measure Unit'
                    formik={formattedFormik}
                    options={[
                      formik.values.unit ?? '',
                      formik.values.subunit ?? '',
                    ]}
                    getOptionValue={(option) => option}
                    getOptionLabel={(option) => option}
                    sx={{ width: 160 }}
                  />
                )}

                <FormTextField
                  name={`batches.${idx}.batch_number`}
                  label='Batch Number'
                  formik={formattedFormik}
                  sx={{ width: 220 }}
                />

                <FormDateField
                  name={`batches.${idx}.expiry_date`}
                  label='Expiry Date (Optional)'
                  formik={formattedFormik}
                  sx={{ width: 220 }}
                />

                {formik.values.batches != null &&
                  formik.values.batches.length > 1 && (
                    <Stack alignItems='center' justifyContent='center'>
                      <IconButton
                        color='error'
                        onClick={() => {
                          const newbatches = formik.values.batches?.filter(
                            (_, i) => i !== idx,
                          );
                          formik.setFieldValue('batches', newbatches);
                        }}
                      >
                        <IconTrash />
                      </IconButton>
                    </Stack>
                  )}
              </Stack>
            ))}

            <Stack direction='column' alignItems='center'>
              <IconButton
                onClick={() =>
                  formik.setFieldValue('batches', [
                    ...(formik.values.batches ?? []),
                    {
                      batch_number: '',
                      expiry_date: null,
                      quantity: 1,
                      unit: formik.values.unit,
                    },
                  ])
                }
              >
                <IconPlus />
              </IconButton>
            </Stack>
          </Stack>
        )}
      </CardSection>

      <CardSection
        title={`Pricing ${formik.values.unit ? `(${formik.values.unit})` : ''}`}
      >
        <Stack
          spacing={2}
          alignItems='center'
          direction={{
            xs: 'column',
            sm: 'row',
          }}
        >
          <FormikNumberInput
            name='procurement_cost'
            label='Procurement Cost (ex-VAT)'
            formik={formattedFormik}
            value={formik.values.procurement_cost}
            isInt={false}
            startIcon={getCurrencySymbol()}
            sx={{
              flex: '1 1 0',
            }}
          />

          <FormikNumberInput
            name='margin'
            label='Mark Up'
            formik={formattedFormik}
            value={formik.values.margin}
            isInt={false}
            min={-100}
            endIcon='%'
            afterInputChange={(newMargin) => {
              formik.setFieldValue(
                'price',
                productUtils.calculatePrice(formik, newMargin),
              );
            }}
            sx={{
              flex: '1 1 0',
            }}
          />

          <FormSelectField
            name='vat_code'
            label='VAT Code'
            formik={formattedFormik}
            options={vatOptions}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.label}
            sx={{
              flex: '1 1 0',
            }}
          />

          {formik.values.vat_code === 'custom' && (
            <FormikNumberInput
              name='vat_percentage'
              label='VAT'
              formik={formattedFormik}
              value={formik.values.vat_percentage}
              isInt={false}
              endIcon='%'
              sx={{
                flex: '1 1 0',
              }}
            />
          )}

          <FormikNumberInput
            name='price'
            label='Price (inc-VAT)'
            formik={formattedFormik}
            value={formik.values.price}
            isInt={false}
            startIcon={getCurrencySymbol()}
            min={0}
            max={999999}
            afterInputChange={(newPrice) => {
              formik.setFieldValue(
                'margin',
                productUtils.calculateMargin(formik, newPrice),
              );
            }}
            sx={{
              flex: '1 1 0',
            }}
          />
        </Stack>

        <FormSwitchField
          name='has_advanced_discounts'
          label='Has Advanced Settings?'
          formik={formattedFormik}
          afterInputChange={(checked) => {
            if (!checked) {
              formik.setFieldValue('discount_1', 0);
              formik.setFieldValue('discount_2', 0);
              formik.setFieldValue('discount_3', 0);
            }
          }}
          sx={{
            my: 1,
          }}
        />

        {formik.values.has_advanced_discounts && (
          <Stack direction='column' spacing={2}>
            <Stack direction='row' alignItems='center' gap={3} mt={1} mb={1}>
              <FormikNumberInput
                name='discount_1'
                label='Discount 1'
                formik={formattedFormik}
                value={formik.values.discount_1}
                isInt={false}
                min={-100}
                max={100}
                endIcon='%'
                sx={{
                  flex: '1 1 0',
                }}
              />

              <FormikNumberInput
                name='discount_2'
                label='Discount 2'
                formik={formattedFormik}
                value={formik.values.discount_2}
                isInt={false}
                min={-100}
                max={100}
                endIcon='%'
                sx={{
                  flex: '1 1 0',
                }}
              />

              <FormikNumberInput
                name='discount_3'
                label='Discount 3'
                formik={formattedFormik}
                value={formik.values.discount_3}
                isInt={false}
                min={-100}
                max={100}
                endIcon='%'
                sx={{
                  flex: '1 1 0',
                }}
              />
            </Stack>

            <InventoryProductFormDiscountsChart
              procurementCost={formik.values.procurement_cost}
              discount1={formik.values.discount_1}
              discount2={formik.values.discount_2}
              discount3={formik.values.discount_3}
              markUp={formik.values.margin}
              vat={formik.values.vat_percentage}
            />
          </Stack>
        )}

        <FormSwitchField
          name='has_fees'
          label='Has Extra Fees?'
          formik={formattedFormik}
          afterInputChange={(checked) => {
            if (!checked) {
              formik.setFieldValue('dispensing_fee', 0);
            }
          }}
          sx={{
            my: 1,
          }}
        />

        {formik.values.has_fees && (
          <FormikNumberInput
            name='dispensing_fee'
            label='Dispensing/Injection Fee (inc-VAT)'
            formik={formattedFormik}
            value={formik.values.dispensing_fee}
            isInt={false}
            startIcon={getCurrencySymbol()}
            sx={{
              width: 240,
            }}
          />
        )}
      </CardSection>

      <CardSection title='Stock Levels'>
        <Stack
          spacing={2}
          direction={{
            xs: 'column',
            sm: 'row',
          }}
        >
          <FormikNumberInput
            name='minimum_stock_level'
            label='Minimum Stock Level'
            formik={formattedFormik}
            value={formik.values.minimum_stock_level}
            sx={{
              width: 180,
            }}
          />

          <FormikNumberInput
            name='optimal_stock_level'
            label='Optimal Stock Level'
            formik={formattedFormik}
            value={formik.values.optimal_stock_level}
            sx={{
              width: 180,
            }}
          />
        </Stack>
      </CardSection>

      <Stack spacing={4}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          px={2}
          spacing={1}
        >
          <div>
            {action === 'update' && (
              <LoadingButton
                variant='contained'
                color='error'
                onClick={() => {
                  deleteProductMutation.mutate({
                    productId: throwIfNullish(productId),
                  });
                }}
                loading={deleteProductMutation.isLoading}
              >
                Delete
              </LoadingButton>
            )}
          </div>

          <Stack direction='row' spacing={1}>
            <Button
              color='inherit'
              component={RouterLink}
              href={paths.inventory.index}
            >
              Cancel
            </Button>

            <LoadingButton
              variant='contained'
              onClick={() => formik.handleSubmit()}
              loading={formik.isSubmitting}
              disabled={!formik.dirty}
            >
              {action === 'create' ? 'Create' : 'Save'}
            </LoadingButton>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
