/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react';
import { useState } from 'react';

import { Button, Divider, IconButton, Menu, MenuItem } from '@mui/material';
import { IconChevronDown, IconX } from '@tabler/icons-react';
import { createPortal } from 'react-dom';
import { match } from 'ts-pattern';

import { useAuth } from '~/hooks/use-auth';
import { getSupabaseConfigSync } from '~/lib/supabase';
import {
  BACKENDS,
  BACKEND_NAMES,
  BackendName,
  CURRENT_BACKEND,
  getBackendOverride,
  setBackendOverride,
} from '~/utils/networkUtils';

function OverlayBanner({
  children,
  color,
  stripeColor,
}: {
  children: React.ReactNode;
  color: string;
  stripeColor: string;
}) {
  const cornerSize = 8;
  const stripeWidth = 5;

  const bannerStyle: React.CSSProperties = {
    position: 'fixed',
    top: '0',
    left: '50%',
    height: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
    background: `repeating-linear-gradient(45deg, ${color}, ${color} ${stripeWidth}px, ${stripeColor} ${stripeWidth}px, ${stripeColor} ${stripeWidth * 2}px)`,
    color: 'white',
    textAlign: 'center',
    fontFamily: 'sans-serif',
    fontWeight: '600',
    fontSize: '12px',
    borderRadius: '0 0 8px 8px ',
    transform: 'translateX(-50%)',
    zIndex: 9999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const beforeAfterStyle: React.CSSProperties = {
    position: 'absolute',
    top: 0,
    width: `${cornerSize}px`,
    height: `${cornerSize}px`,
  };

  return createPortal(
    <div style={bannerStyle}>
      <div
        style={{
          ...beforeAfterStyle,
          left: `-${cornerSize}px`,
          backgroundImage: `radial-gradient(circle at 0 100%, transparent ${cornerSize}px, ${color} ${cornerSize}px)`,
        }}
      />
      <div
        style={{
          ...beforeAfterStyle,
          right: `-${cornerSize}px`,
          backgroundImage: `radial-gradient(circle at 100% 100%, transparent ${cornerSize}px, ${color} ${cornerSize}px)`,
        }}
      />
      {children}
    </div>,
    document.body,
  );
}

function DevModeBanner() {
  const { isAuthenticated } = useAuth();

  const haveBackendOverride = getBackendOverride() != null;
  const usingLocalhost =
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1';

  const bannerShownByDefault = usingLocalhost || haveBackendOverride;
  const allowChangingBackend =
    usingLocalhost || haveBackendOverride || isAuthenticated;

  const [showBanner, setShowBanner] = useState(bannerShownByDefault);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  if (!showBanner) return null;

  const backend = CURRENT_BACKEND;
  const supabaseUrl = getSupabaseConfigSync()?.supabase.url;

  const supabaseBackendName = match(supabaseUrl)
    .returnType<BackendName | null>()
    .with('http://127.0.0.1:54321', () => 'LOCAL')
    .with('http://localhost:54321', () => 'LOCAL')
    .with('https://bibzwlnujjpkwtbsirqy.supabase.co', () => 'STAGING')
    .with('https://auth.lupapets.com', () => 'PRODUCTION')
    .otherwise(() => null);

  const backendColours = {
    LOCAL: '#005f2b',
    STAGING: '#007f7f',
    PRODUCTION: '#c8102e',
    UNKNOWN: '#000',
  };

  let displayBackendName = BACKENDS[backend].displayName;
  if (supabaseBackendName !== backend) {
    const supabaseBackendDisplayName =
      supabaseBackendName && BACKENDS[supabaseBackendName]
        ? BACKENDS[supabaseBackendName].displayName
        : 'UNKNOWN';

    displayBackendName += ` (${supabaseBackendDisplayName} DB)`;
  }

  const handleBackendChange = (newBackend: BackendName | null) => {
    setBackendOverride(newBackend);
    setMenuAnchorEl(null);
  };

  return (
    <OverlayBanner
      color={backendColours[backend]}
      stripeColor={backendColours[supabaseBackendName ?? 'UNKNOWN']}
    >
      Env:
      <Button
        onClick={(event) =>
          allowChangingBackend
            ? setMenuAnchorEl(menuAnchorEl == null ? event.currentTarget : null)
            : null
        }
        endIcon={
          allowChangingBackend ? <IconChevronDown size={12} /> : undefined
        }
        sx={{
          color: 'white',
          textTransform: 'none',
          padding: 0,
          marginLeft: '4px',
          minWidth: 'auto',
          font: 'inherit',
          '& .MuiButton-endIcon': {
            marginLeft: '2px',
          },
          pointerEvents: allowChangingBackend ? 'auto' : 'none',
        }}
      >
        {displayBackendName}
      </Button>
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
        MenuListProps={{ dense: true }}
      >
        {BACKEND_NAMES.map((backendName) => (
          <MenuItem
            key={backendName}
            onClick={() => handleBackendChange(backendName)}
          >
            {BACKENDS[backendName].displayName}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem onClick={() => handleBackendChange(null)}>
          Reset to Default
        </MenuItem>
      </Menu>
      <IconButton
        onClick={() => setShowBanner(false)}
        sx={{
          color: 'white',
          marginLeft: 2,
          marginRight: -1,
          padding: 0,
        }}
      >
        <IconX size={12} />
      </IconButton>
    </OverlayBanner>
  );
}

export default DevModeBanner;
