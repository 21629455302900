import { createContext } from 'react';

export const initialState: {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: any;
  session: any;
} = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  session: null,
};

export const AuthContext = createContext({
  ...initialState,
  signIn: (_username: string, _password: string) => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
});

export const AuthConsumer = AuthContext.Consumer;
